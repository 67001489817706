export default class MetaData {
    private _name: string;
    private _description: string;
    private _email: string;
    private _send: boolean;

    constructor(
        name: string = '',
        description: string = '',
        email: string = '',
        send: boolean = false
    ) {
        this._name = name;
        this._description = description;
        this._email = email;
        this._send = send;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get send(): boolean {
        return this._send;
    }

    set send(value: boolean) {
        this._send = value;
    }

    getData() {
        return {
            name: this.name,
            description: this.description,
            email: this.email,
            send: this.send
        }
    }

    setData(data: any = {}) {
        Object.keys(data).map((key: string) => {
            switch (key) {
                case 'name':
                    this.name = data[key];
                    break;
                case 'description':
                    this.description = data[key];
                    break;
                case 'email':
                    this.email = data[key];
                    break;
                case 'send':
                    this.send = data[key];
                    break;
            }
            return key;
        });
    }
}