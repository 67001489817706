import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';
import {TextValidator} from 'react-material-ui-form-validator';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import SectionCard from '../../Partials/SectionCard';
import AdditionalInputArea from '../../Partials/AdditionalInputArea';
import RegulatorSVG from '../../SVGs/Regulator';
import {Norm2018, Norm2022} from "../../../Norms";

const SystemStyles = (theme: Theme) => createStyles({
    root: {}
});

interface SystemProps extends WithStyles<typeof SystemStyles>, WithTranslation {
    norm: Norm2018|Norm2022,
    updateNorm: any,
    disabled: boolean
}

interface SystemState {

}

class System extends React.Component<SystemProps, SystemState> {
    handleSelectedChange = (key: any, event: any) => {
        const value = event.target.value;
        this.props.updateNorm({[key]: value});
    };

    render() {
        const classes = this.props.classes;
        const series = this.props.norm.series;

        return (
            <SectionCard rootClass={classes.root}
                         disabled={this.props.disabled}
                         avatar={<RegulatorSVG/>}
                         title={<Typography variant="h2">{this.props.t('system.headline')}</Typography>}
            >
                {/* @ts-ignore */}
                <TextValidator
                    variant="standard"
                    label={this.props.t('system.serie')}
                    name="serie"
                    select
                    value={this.props.norm.system.id}
                    onChange={this.handleSelectedChange.bind(this, 'serie')}
                    disabled={this.props.disabled}
                >
                    {series.map((serie, i) => (
                        <MenuItem key={i}
                                  value={serie}>{this.props.t('system.items.' + serie)}</MenuItem>
                    ))}
                </TextValidator>
                <AdditionalInputArea norm={this.props.norm}
                                     updateNorm={this.props.updateNorm.bind(this)}
                                     disabled={this.props.disabled}/>
            </SectionCard>
        );
    }

    getEmptyString = (value: any) => {
        if (value === 0 || value === '' || value === undefined || value === null) {
            return '';
        } else {
            return value
        }
    };
}

const styledSystem = withStyles(SystemStyles)(System);

export default withTranslation()(styledSystem);