import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const VentilationStyles = (theme: Theme) => createStyles({
    a: {
        strokeWidth: 0.25
    }
});

interface VentilationProps extends WithStyles<typeof VentilationStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface VentilationState {
    color: string,
    width: number,
    height: number,
}

class Ventilation extends React.Component<VentilationProps, VentilationState> {
    constructor(props: VentilationProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 18,
            height: this.props.height ? this.props.height : 18,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.259 20.385">
                <g transform="translate(-384.137 -376.081)">
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M437.874,412l.371-.529a9.656,9.656,0,0,1,2.337,1.814c.4.456.808.908,1.232,1.344a3.219,3.219,0,0,0,.666.534,1.266,1.266,0,0,0,1.729-.456,5.441,5.441,0,0,0,.851-1.826,10.376,10.376,0,0,0,.5-2.962,5.2,5.2,0,0,0-.4-2,2.866,2.866,0,0,0-3.647-1.794,9.462,9.462,0,0,0-2.907,1.415l-.27.185-.415-.552c.525-.32,1.029-.654,1.558-.944a7.182,7.182,0,0,1,2.514-.867,3.319,3.319,0,0,1,3.5,1.737,5.442,5.442,0,0,1,.712,3.328,10.644,10.644,0,0,1-.963,3.834,4.879,4.879,0,0,1-.846,1.2,1.723,1.723,0,0,1-2.1.345,4.386,4.386,0,0,1-1-.767c-.617-.61-1.173-1.282-1.8-1.877a11.842,11.842,0,0,0-1.3-.944Z"
                          transform="translate(-41.956 -22.792)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M390.6,427.164l.272.6a10.918,10.918,0,0,1-2.154.951c-.776.178-1.553.352-2.329.533a3.431,3.431,0,0,0-.521.16,1.269,1.269,0,0,0-.774,1.869,6.416,6.416,0,0,0,1.005,1.5,8.486,8.486,0,0,0,3.229,2.442,3.987,3.987,0,0,0,2.469.234,2.586,2.586,0,0,0,1.8-1.482,7.077,7.077,0,0,0,.53-3.153c-.007-.363-.032-.725-.05-1.113l.642-.069c.018.188.04.358.05.528a9.327,9.327,0,0,1-.391,3.672,3.48,3.48,0,0,1-3.861,2.356,6.205,6.205,0,0,1-3.208-1.371,10.249,10.249,0,0,1-2.5-2.627,4.022,4.022,0,0,1-.483-1.193,1.727,1.727,0,0,1,.964-2.04,8.867,8.867,0,0,1,1.577-.491c.6-.162,1.224-.257,1.812-.455.554-.186,1.076-.469,1.612-.709C390.385,427.263,390.479,427.219,390.6,427.164Z"
                          transform="translate(0 -39.887)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M402.142,383.732l-.617-.054a9.943,9.943,0,0,1,.345-2.706c.211-.631.413-1.267.593-1.907a2.922,2.922,0,0,0,.148-.916,1.3,1.3,0,0,0-1.359-1.26,8.571,8.571,0,0,0-3.469.66,6.142,6.142,0,0,0-2.435,1.5,3.4,3.4,0,0,0-1.069,2.435,2.652,2.652,0,0,0,.672,1.681,8.337,8.337,0,0,0,2.895,2.122c.123.061.248.119.379.182-.019.06-.031.111-.051.158-.061.142-.126.283-.206.46-.341-.17-.676-.324-1-.5a9.116,9.116,0,0,1-2.216-1.627,3.543,3.543,0,0,1-.214-5,6.055,6.055,0,0,1,2.349-1.766,10.7,10.7,0,0,1,3.879-.984,2.722,2.722,0,0,1,1.777.459,1.715,1.715,0,0,1,.717,1.712,14.132,14.132,0,0,1-.553,2.1,9.478,9.478,0,0,0-.5,2.931,1.355,1.355,0,0,1-.02.207A.665.665,0,0,1,402.142,383.732Z"
                          transform="translate(-7.308 0)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M422.57,417.2a2.118,2.118,0,1,1-2.064-2.114A2.108,2.108,0,0,1,422.57,417.2Zm-2.124-1.44a1.442,1.442,0,1,0,.029,2.884,1.442,1.442,0,1,0-.029-2.884Z"
                          transform="translate(-26.662 -30.431)"/>
                </g>
            </svg>
        );
    }
}

const styledVentilation = withStyles(VentilationStyles)(Ventilation);

export default withTranslation()(styledVentilation);