import AbstractSystemData from "../FormData/System/AbstractSystemData";
import {
    MSerieSystemData,
    PacIfSystemData,
} from "../FormData/SystemData";
import LocationData from "../FormData/LocationData";
import MetaData from "../FormData/MetaData";
import {AmountUnit, Multipliers, RefrigerantData} from "../DataObjects";
import {getEmptyStringOr, round} from "../Helper";

export default class Norm2022 {
    private _system: AbstractSystemData;
    private _location: LocationData;
    private _meta: MetaData;
    private _diagram: string;

    static get identifier() {
        return '2022';
    }

    get identifier() {
        return Norm2022.identifier;
    }

    static get series() {
        return [
            'mSerie',
            'mrSlim',
            'vrf',
            'hvrf',
            'pacIf'
        ];
    }

    get series() {
        return Norm2022.series;
    }

    static get refrigerants() {
        return [
            new RefrigerantData(
                'r32',
                new AmountUnit(0.307, 'kg/m³'),
                new AmountUnit(0.063, 'kg/m³'),
                new AmountUnit(0.061, 'kg/m³'),
                new AmountUnit(0.15, 'kg/m³'),
                new AmountUnit(0.3, 'kg/m³')
            ),
        ];
    }

    get refrigerants() {
        return Norm2022.refrigerants;
    }

    constructor(
        system: AbstractSystemData = new MSerieSystemData(),
        location: LocationData = new LocationData(),
        meta: MetaData = new MetaData(),
        diagram: string = '',
    ) {
        this._system = system;
        this._system.normIdentifier = Norm2022.identifier;
        this._location = location;
        this._meta = meta;
        this._diagram = diagram;
    }

    get system(): AbstractSystemData {
        return this._system;
    }

    set system(value: AbstractSystemData) {
        this._system = value;
    }

    get location(): LocationData {
        return this._location;
    }

    set location(value: LocationData) {
        this._location = value;
    }

    get meta(): MetaData {
        return this._meta;
    }

    set meta(value: MetaData) {
        this._meta = value;
    }

    get multipliers(): Multipliers {
        return new Multipliers(
            26 * this.system.refrigerant.lfl.amount,
            0.5 * this.system.refrigerant.lfl.amount,
            0.75 * this.system.refrigerant.lfl.amount,
            260 * this.system.refrigerant.lfl.amount
        );
    }

    get diagram(): string {
        return this._diagram;
    }

    set diagram(value: string) {
        this._diagram = value;
    }

    get heightForBreakpoints(): number {
        if ((this.system.exitHeight.validValue >= 1.8 && this.system.exitHeight.validValue <= 2.2) || this.system.circulation) {
            return this.location.height.validValue;
        }

        return this.system.exitHeight.validValue;
    }

    getData() {
        return {
            ...this.system.getData(),
            ...this.location.getData(),
            ...this.meta.getData(),

            diagram: this.diagram,

            // calculation
            minArea: round(this.calcMinArea(), 'ceil'),
            maxRefrigerantAmount: round(this.calcMaxRefrigerantAmount(), 'floor'),
            deadZone: this.calcBreakpointDeadZone(),
            zone: this.calcZone(),
            numberOfSafetyMeasures: this.calcNumberOfSafetyMeasures(),

            // results
            minAreaReachable: this.isMinAreaReachable(),
            installationPossible: this.isInstallationPossible(),
            circulationNecessary: this.isCirculationNecessary(),
            toxicityNecessary: this.isToxicityHandlingNecessary(),
            necessaryMeasures: this.getNecessaryMeasures(),

            valid: {
                ...this.system.getData().valid,
                ...this.location.getData().valid,
            },

            show: {
                hint: this.showHint(),
                measures: this.showMeasures(),
                diagram: this.showDiagram()
            }
        }
    }

    setData(data: any = {}) {
        this.system.setData(data);
        this.location.setData(data);
        this.meta.setData(data);

        Object.keys(data).map((key: string) => {
            switch (key) {
                case 'diagram':
                    this.diagram = data[key];
                    break;
            }
            return key;
        });
    }

    /* * * * * * * *
     * Calculation *
     * * * * * * * */

    calcMinArea(
        lfl: number = this.system.refrigerant.lfl.amount,
        exitHeight: number = this.system.exitHeight.validValue,
        refrigerantAmount: number = this.system.refrigerantAmount.validValue,
    ): number {
        let z0 = Math.pow(refrigerantAmount / (2.5 * exitHeight * Math.pow(lfl, 1.25)), 2);

        if (this.system.circulation) {
            z0 = refrigerantAmount / (0.75 * lfl * Math.min(exitHeight, 2.2));
        }

        if (this.system instanceof MSerieSystemData && this.system.floorUnit) {
            z0 = refrigerantAmount / (0.75 * lfl * 2.2);
        }

        const z1 = refrigerantAmount / (this.multipliers.breakpointOneTwo * this.heightForBreakpoints);

        let z2 = 999;
        if (this.calcNumberOfSafetyMeasures(2) === 0) {
            z2 = refrigerantAmount / (this.multipliers.breakpointTwoThree * this.heightForBreakpoints);
        }

        return Math.min(z0, z1, z2);
    }

    calcPlainMaxRefrigerantAmount(
        lfl: number = this.system.refrigerant.lfl.amount,
        exitHeight: number = this.system.exitHeight.validValue,
        area: number = this.location.area.validValue,
    ): number {
        switch (true) {
            case (this.system instanceof MSerieSystemData && this.system.floorUnit):
                return 0.75 * lfl * 2.2 * area;
            case (this.system.circulation):
                return 0.75 * lfl * Math.min(exitHeight, 2.2) * area;
            default:
                const maxRefrigerantAmount = 2.5 * Math.pow(lfl, 1.25) * exitHeight * Math.pow(area, 0.5)
                const notToExceed = 0.75 * lfl * exitHeight * area;
                return Math.min(maxRefrigerantAmount, notToExceed);
        }
    }

    calcMaxRefrigerantAmount(
        lfl: number = this.system.refrigerant.lfl.amount,
        exitHeight: number = this.system.exitHeight.validValue,
        area: number = this.location.area.validValue,
        calcSmallest = false,
    ): number {
        switch (true) {
            case (this.system instanceof PacIfSystemData && !this.system.inBuilding && !calcSmallest):
                return this.calcBreakpointDeadZone();
            default:
                let z0 = this.calcPlainMaxRefrigerantAmount(lfl, exitHeight, area);
                if (z0 < 6 * lfl) {
                    z0 = 6 * lfl;
                } else if (z0 > this.multipliers.maxRefrigerantAmount) {
                    z0 = this.multipliers.maxRefrigerantAmount;
                }

                const z1 = this.calcBreakpointOneTwo(area);

                return Math.max(z0, z1);
        }
    }

    calcMaxRefrigerantAmountForHint(): number {
        let z01 = this.calcMaxRefrigerantAmount();

        let z2 = 0;
        if (this.calcNumberOfSafetyMeasures(2) === 0) {
            z2 = this.calcBreakpointTwoThree(this.location.area.validValue);
        }

        return Math.max(z01, z2);
    }

    calcBreakpointOneTwo(
        area: number = this.location.area.validValue,
        height: number = this.heightForBreakpoints,
    ): number {
        return this.multipliers.breakpointOneTwo * height * area;
    }

    get maxBreakpointOneTwo(): number {
        return this.calcBreakpointOneTwo(this.location.area.max);
    }

    calcBreakpointTwoThree(
        area: number = this.location.area.validValue,
        height: number = this.heightForBreakpoints,
    ): number {
        return this.multipliers.breakpointTwoThree * height * area;
    }

    get maxBreakpointTwoThree(): number {
        return this.calcBreakpointTwoThree(this.location.area.max);
    }

    calcBreakpointDeadZone(): number {
        return this.multipliers.breakpointDeadzone;
    }

    calcZone(): number {
        if (this.system.refrigerantAmount.validValue < this.calcMaxRefrigerantAmount()) {
            return 0;
        } else if (this.system.refrigerantAmount.validValue < this.calcBreakpointOneTwo()) {
            return 1;
        } else if (this.system.refrigerantAmount.validValue < this.calcBreakpointTwoThree()) {
            return 2;
        } else {
            return 3;
        }
    }

    calcNumberOfSafetyMeasures(
         zone: number = this.calcZone()
    ): number {
        switch (zone) {
            case 2:
                let basementBonus = (this.location.basement && !this.system.circulation) ? 1 : 0;

                if (this.system.exitHeight.validValue < 1.8 && !this.system.circulation) {
                    return basementBonus + 1;
                }

                return basementBonus;
            case 3:
                if (this.location.basement) {
                    return 999;
                } else {
                    return 2;
                }
            default:
                return 0;
        }
    }

    calcToxicityMaxRefrigerantAmount(): number {
        return 6 * this.system.refrigerant.lfl.amount;
    }

    calcToxicityMaxVolume(): number {
        return this.calcToxicityMaxRefrigerantAmount() / this.system.refrigerant.atel.amount;
    }

    calcToxicityMaxArea(): number {
        return this.calcToxicityMaxVolume() / this.location.height.validValue;
    }

    /* * * * * *
     * Results *
     * * * * * */

    isMinAreaReachable(): boolean {
        return this.system.refrigerantAmount.validValue < this.calcMaxRefrigerantAmount(
            this.system.refrigerant.lfl.amount,
            this.system.exitHeight.validValue,
            this.location.area.max,
        ) || this.system.refrigerantAmount.validValue < this.calcBreakpointOneTwo(this.location.area.max);
    }

    isInstallationPossible(): boolean {
        if (this.system.refrigerantAmount.validValue > this.calcBreakpointDeadZone()) {
            return false;
        }

        return this.calcNumberOfSafetyMeasures() !== 999;
    }

    isCirculationNecessary(): boolean {
        switch (true) {
            case (this.system instanceof MSerieSystemData && this.system.floorUnit):
                return false;
            default:
                if (this.system.circulation) {
                    return false;
                }

                return (this.system.exitHeight.validValue < 1.8 && this.system.refrigerantAmount.validValue >= this.calcMaxRefrigerantAmount());
        }
    }

    isToxicityHandlingNecessary(): boolean {
        let necessary = this.system.refrigerantAmount.validValue / this.location.volume.validValue > this.system.refrigerant.atel.amount;
        if (this.system.refrigerantAmount.validValue > 6 * this.system.refrigerant.lfl.amount) {
            necessary = false;
        }

        return necessary;
    }

    isDetectorNecessary() {
        if (this.system instanceof PacIfSystemData) {
            return this.system.refrigerantAmount.validValue > round(this.calcMaxRefrigerantAmount(
                this.system.refrigerant.lfl.amount,
                this.system.airflowHeight.validValue,
                this.system.smallestArea.validValue,
                true,
            ), 'floor');
        }

        return false;
    }

    isSmallestToxic() {
        if (this.system instanceof PacIfSystemData) {
            if (this.isDetectorNecessary()) {
                return false;
            }

            if (this.system.refrigerantAmount.validValue > 6 * this.system.refrigerant.lfl.amount) {
                return false;
            }

            return (this.system.refrigerantAmount.validValue /
                (this.system.smallestArea.validValue * this.system.airflowHeight.validValue)
            ) > this.system.refrigerant.atel.amount;
        }
    }

    getNecessaryMeasures(): any[] {
        let measures = [];

        if (this.isInstallationPossible()) {
            if (!(this.system instanceof PacIfSystemData) || this.system.inBuilding) {
                const numberOfSafetyMeasures = this.calcNumberOfSafetyMeasures();

                if (numberOfSafetyMeasures === 2) {
                    measures.push({
                        name: 'alert',
                        items: ['alert'],
                    });
                }

                if (numberOfSafetyMeasures > 0 || this.isToxicityHandlingNecessary()) {
                    const items = [
                        'ventilation',
                        'valve'
                    ];

                    if (numberOfSafetyMeasures === 1) {
                        items.push('alert');
                    }

                    if (this.isCirculationNecessary()) {
                        items.push('circulation');
                    }

                    measures.push({
                        name: 'standard',
                        items: items,
                        necessary: numberOfSafetyMeasures
                    });
                }
            }

            if (this.system instanceof PacIfSystemData) {
                if (this.isDetectorNecessary() || this.isSmallestToxic()) {
                    measures.push({
                        name: 'detector',
                        items: ['detector']
                    });
                }

                measures.push({
                    name: 'pacIfHint',
                    items: []
                });
            }
        }

        return measures;
    }

    /* * * * * * *
     * Templates *
     * * * * * * */

    showHint() {
        switch (true) {
            case (this.system instanceof PacIfSystemData && !this.system.inBuilding):
                return false;
            default:
                return this.getNecessaryMeasures().length > 0;
        }
    }

    showMeasures() {
        return this.getNecessaryMeasures().length > 0;
    }

    showDiagram() {
        switch (true) {
            case (this.system instanceof PacIfSystemData && !this.system.inBuilding):
                return false;
            default:
                return true;
        }
    }

    showButton() {
        return navigator.onLine;
    }

    /* * * * * *
     * Helpers *
     * * * * * */
    public isSystemReady() {
        return this.system.isReady();
    }

    public isLocationReady() {
        switch (true) {
            case (this.system instanceof PacIfSystemData && !this.system.inBuilding):
                return true;
            default:
                return this.location.isReady();
        }
    }

    public isInputError(): string | boolean {
        if (this.system instanceof PacIfSystemData) {
            if (getEmptyStringOr(this.system.airflowHeight.value) === '' ||
                getEmptyStringOr(this.system.smallestArea.value) === ''
            ) {
                return 'pac-if';
            }

            if (!this.system.inBuilding) {
                if (getEmptyStringOr(this.system.exitHeight.value) === '' ||
                    getEmptyStringOr(this.system.refrigerantAmount.value) === '') {
                    return 'required';
                } else {
                    return false;
                }
            }
        }

        if (getEmptyStringOr(this.location.area.value) === '' ||
            getEmptyStringOr(this.location.volume.value) === '' ||
            getEmptyStringOr(this.system.exitHeight.value) === '' ||
            getEmptyStringOr(this.system.refrigerantAmount.value) === '') {
            return 'required';
        }

        let height = parseFloat('' + this.location.height.value);
        let exitHeight = parseFloat('' + this.system.exitHeight.value);
        if (this.location.roomCutEven && height < exitHeight) {
            return 'exitHeight';
        }

        return false;
    }
}