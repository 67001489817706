import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import SectionCard from '../Partials/SectionCard';
import ResultBox from "../Partials/ResultBox";
import HintBox from "../Partials/HintBox";
import SignHintBox from "../Partials/SignHintBox";
import MeasuresBox from "../Partials/MeasuresBox";
import DiagramBox from "../Partials/DiagramBox";

import ConnectionSVG from '../SVGs/Connection';
import {Norm2018, Norm2022} from "../../Norms";
import {SMextSystemData} from "../../FormData/SystemData";

const ResultStyles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(4) + ' auto'
    },
    timelineIcon: {
        fontSize: '3rem'
    },
    cardContent: {
        ...theme.grid.oneColumn
    },
    cardContentSingle: {
        ...theme.grid.oneColumn
    },
    leftSide: {
        ...theme.grid.oneColumn
    },
    rightSide: {
        ...theme.grid.oneColumn,
        gridTemplateRows: '1fr auto',
    },
    topLeft: {},
    topBoth: {},
    topRight: {},
    rightBoth: {},
    bottomRight: {},
    bottomBoth: {},
    bottomLeft: {},
    leftBoth: {},
    headerIcon: {
        fontSize: '2rem'
    },
    relativeWrap: {
        position: 'relative',
        paddingBottom: 60
    },
    button: {
        marginLeft: '8.2%',
        width: '89.83%'
    },
    '@media (min-width: 800px)': {
        cardContent: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: theme.spacing(6)
        },
        leftSide: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: theme.spacing(6)
        },
        topLeft: {
            gridColumn: '1 / span 1',
            gridRow: '1 / span 1',
        },
        topBoth: {
            gridColumn: '1 / span 2',
            gridRow: '1 / span 1',
        },
        topRight: {
            gridColumn: '2 / span 1',
            gridRow: '1 / span 1',
        },
        rightBoth: {
            gridColumn: '2 / span 1',
            gridRow: '1 / span 2',
        },
        bottomRight: {
            gridColumn: '2 / span 1',
            gridRow: '2 / span 1',
        },
        bottomBoth: {
            gridColumn: '1 / span 2',
            gridRow: '2 / span 1',
        },
        bottomLeft: {
            gridColumn: '1 / span 1',
            gridRow: '2 / span 1',
        },
        leftBoth: {
            gridColumn: '1 / span 1',
            gridRow: '1 / span 2',
        },
    },
    '@media (min-width: 1400px)': {
        cardContent: {
            display: 'grid',
            gridTemplateColumns: '6fr 5fr',
            gridGap: theme.spacing(6)
        }
    }
});

interface ResultProps extends WithStyles<typeof ResultStyles>, WithTranslation {
    norm: Norm2018|Norm2022,
    updateNorm: any,
    handleNext: any
}

interface ResultState {

}

class Result extends React.Component<ResultProps, ResultState> {
    render() {
        const classes = this.props.classes;

        return (
            <SectionCard rootClass={classes.root}
                         contentClass={this.props.norm.showDiagram() ? classes.cardContent : classes.cardContentSingle}
                         avatar={<ConnectionSVG/>}
                         title={<Typography variant="h2">{this.props.t('results.headline')}</Typography>}>
                <Box className={classes.leftSide}>
                    <ResultBox
                        className={this.props.norm.showMeasures() ? classes.topLeft : classes.topBoth}
                        norm={this.props.norm}/>
                    {this.props.norm.showHint() && this.getHint()}
                    {this.props.norm.showMeasures() &&
                    <MeasuresBox className={this.props.norm.showHint() ? classes.rightBoth : classes.topRight}
                                 norm={this.props.norm}/>}
                </Box>
                <Box className={classes.rightSide}>
                    {this.props.norm.showDiagram() &&
                    <DiagramBox norm={this.props.norm}
                                updateNorm={this.props.updateNorm.bind(this)}/>}
                    {this.props.norm.showButton() && this.getButton()}
                </Box>
            </SectionCard>
        );
    }

    getHint(){
        const classes = this.props.classes;

        if(this.props.norm instanceof Norm2018 && this.props.norm.system instanceof SMextSystemData){
            return <SignHintBox
                className={this.props.norm.showMeasures() ? classes.bottomLeft : classes.bottomBoth}
                norm={this.props.norm}/>;
        }

        return <HintBox
            className={this.props.norm.showMeasures() ? classes.bottomLeft : classes.bottomBoth}
            norm={this.props.norm}/>;
    }

    getButton(){
        const classes = this.props.classes;

        if(this.props.norm instanceof Norm2018 && this.props.norm.system instanceof SMextSystemData){
            return <Button
                variant="contained"
                color="primary"
                onClick={this.download.bind(this, require('../../images/' + this.props.i18n.language + '/sign.png'))}>
                {this.props.t('results.sign.download')}
            </Button>;
        }

        return <Button
            variant="contained"
            color="primary"
            className={this.props.norm.showDiagram() ? classes.button : ''}
            onClick={this.handleNext.bind(this)}>
            {this.props.t('results.diagram.create')}
        </Button>;
    }

    download = (url: string) => {
        let a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.setAttribute('download', '');
        a.click();
    }

    handleNext = () => {
        this.props.handleNext();
    };
}

const styledResult = withStyles(ResultStyles)(Result);

export default withTranslation()(styledResult);