import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const ToxicityStyles = (theme: Theme) => createStyles({
    a: {
        fill: 'none !important',
        strokeWidth: 1.5
    }
});

interface ToxicityProps extends WithStyles<typeof ToxicityStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface ToxicityState {
    color: string,
    width: number,
    height: number,
}

class Toxicity extends React.Component<ToxicityProps, ToxicityState> {
    constructor(props: ToxicityProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 30,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.75 36.75">
                <g transform="translate(-62.915 -23.625)">
                    <path className={classes.a} stroke={this.state.color}
                          d="M81.29,24.375A17.625,17.625,0,1,1,63.665,42,17.625,17.625,0,0,1,81.29,24.375Z"/>
                    <path fill={this.state.color}
                          d="M87.364,51.46l.027-.046L87.4,51.4l.018-.054,0-.015.009-.06v0a.377.377,0,0,0,0-.075v0l-.018-.07,0-.009-.03-.063-3.91-6.406h0l-.018-.025-.03-.039-.023-.02-.035-.031-.029-.017-.038-.021-.033-.012-.038-.013-.03,0-.045-.007h-.027l-.05,0-.027.007-.048.012-.027.013-.045.022h0a3.321,3.321,0,0,1-3.436.014h0l-.034-.016-.039-.018-.037-.01-.038-.009H79.3l-.046,0-.021,0-.054.008-.01,0a.419.419,0,0,0-.128.061l-.011.01-.047.042-.013.018-.035.046h0l-3.859,6.434h0l-.012.025-.022.047-.007.026-.012.05v.025l0,.051,0,.027.007.048.008.022.017.05.013.02.026.046.017.019.035.039.017.012.047.035h0a11.621,11.621,0,0,0,12.027-.046h0l.025-.019.039-.029.016-.019.035-.04Zm-11.4-.313,3.457-5.763a4.092,4.092,0,0,0,3.558-.014l3.5,5.737a10.791,10.791,0,0,1-10.517.04Z"/>
                    <path fill={this.state.color}
                          d="M87.2,31.713h0l-.052-.024-.021-.01-.051-.012-.024-.007h-.043l-.034,0-.03.005-.045.006-.02.007-.052.019-.015.009-.051.029-.023.021-.034.031-.03.04-.018.025h0l-3.861,6.434-.023.05-.011.022-.009.04-.009.036v.032l0,.045.005.03.006.045.008.021.018.05.017.03.022.036.019.022.033.036.009.007.055.041h0a3.314,3.314,0,0,1,1.611,2.846.4.4,0,0,0,.4.4h7.5a.4.4,0,0,0,.395-.4A11.606,11.606,0,0,0,87.2,31.713Zm-3.532,6.656,3.457-5.762a10.8,10.8,0,0,1,4.909,8.67h-6.72A4.108,4.108,0,0,0,83.666,38.369Z"/>
                    <path fill={this.state.color}
                          d="M77.9,41.666a3.317,3.317,0,0,1,1.612-2.846l.027-.021.037-.027.018-.021.033-.037.007-.011.032-.055.007-.019.019-.053,0-.017a.4.4,0,0,0,.006-.134l0-.009-.017-.067,0-.01-.03-.062-3.86-6.434h0l-.02-.027-.028-.037-.024-.021-.033-.03-.025-.015-.042-.024-.026-.01-.045-.016-.022,0-.053-.008h-.016l-.061,0-.011,0-.064.016-.007,0-.066.031h0a11.605,11.605,0,0,0-5.639,9.959.4.4,0,0,0,.4.4h7.5A.4.4,0,0,0,77.9,41.666ZM75.322,32.6l3.457,5.763a4.106,4.106,0,0,0-1.646,2.907h-6.72A10.8,10.8,0,0,1,75.322,32.6Z"/>
                    <path fill={this.state.color}
                          d="M79.065,41.672a2.118,2.118,0,1,0,2.118-2.118A2.118,2.118,0,0,0,79.065,41.672Zm1.134-.984a1.392,1.392,0,1,1-.408.984A1.384,1.384,0,0,1,80.2,40.688Z"/>
                </g>
            </svg>
        );
    }
}

const styledToxicity = withStyles(ToxicityStyles)(Toxicity);

export default withTranslation()(styledToxicity);