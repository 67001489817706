import React, {PropsWithChildren} from 'react';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {StepIconProps} from '@mui/material/StepIcon';

const StepIconStyles = makeStyles({
    root: {
        backgroundColor: '#464646',
        zIndex: 1,
        width: 45,
        height: 45,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background-color 1s ease-in-out',
        '& path,& rect,& line,& circle,& polyline': {
            stroke: '#000',
            fill: '#000',
            transition: 'stroke 1s ease-in-out, fill 1s ease-in-out',
        },
        '& .strokenone': {
            stroke: 'none'
        },
        '& .fillnone': {
            fill: 'none'
        },
        '& .strokeWidth05': {
            strokeWidth: 0.5,
        },
        '& .strokeWidth075': {
            strokeWidth: 0.75,
        },
        '& .strokemiterlimit10': {
            strokeMiterlimit: 10
        }
    },
    active: {
        backgroundColor: '#fff',
        '& path,& rect,& line,& circle,& polyline': {
            stroke: '#464646',
            fill: '#464646'
        },
    },
    completed: {
        backgroundColor: '#fff',
        '& path,& rect,& line,& circle,& polyline': {
            stroke: '#464646',
            fill: '#464646'
        },
    },
});

export default function StepIconGenerator(props: PropsWithChildren<StepIconProps>) {
    const classes = StepIconStyles();
    const {active, completed} = props;

    return (
        <div className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}>
            {props.children}
        </div>
    );
}