import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const WarningStyles = (theme: Theme) => createStyles({
    a: {
        strokeWidth: 0.5
    }
});

interface WarningProps extends WithStyles<typeof WarningStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface WarningState {
    color: string,
    width: number,
    height: number,
}

class Warning extends React.Component<WarningProps, WarningState> {
    constructor(props: WarningProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 30,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.58 30.621">
                <g transform="translate(-230.987 -363.92)">
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M247.349,394.288q-7.033,0-14.066,0a3.819,3.819,0,0,1-.684-.05,1.471,1.471,0,0,1-1.189-2.179c.207-.485.48-.942.732-1.406q5.849-10.791,11.7-21.581c.694-1.28,1.383-2.564,2.091-3.838a2.578,2.578,0,0,1,.537-.672,1.231,1.231,0,0,1,1.733.033,2.779,2.779,0,0,1,.519.688q3.808,7.028,7.6,14.063,3.321,6.151,6.637,12.3a2.982,2.982,0,0,1,.33.888,1.458,1.458,0,0,1-1.454,1.752c-.11,0-.22,0-.33,0H247.349Zm-.019-.8v.007H261.4a3.638,3.638,0,0,0,.449-.011.682.682,0,0,0,.647-.9,1.966,1.966,0,0,0-.2-.495q-2.8-5.205-5.616-10.407-4.317-7.995-8.635-15.99c-.168-.311-.345-.67-.742-.659-.375.01-.548.355-.713.659q-2.717,5.014-5.437,10.027-4.438,8.185-8.873,16.372a2.263,2.263,0,0,0-.189.469.637.637,0,0,0,.5.863,2.759,2.759,0,0,0,.651.066Q240.282,393.493,247.33,393.49Z"
                          transform="translate(0)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color} d="M293.946,405.564h.761V415.1h-.761Z" transform="translate(-47.031 -31.011)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color} d="M294.7,457.088h-.751v-1.6h.751Z" transform="translate(-47.032 -68.455)"/>
                </g>
            </svg>
        );
    }
}

const styledWarning = withStyles(WarningStyles)(Warning);

export default withTranslation()(styledWarning);