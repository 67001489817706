import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {round} from "../../Helper";
import {Norm2018, Norm2022} from "../../Norms";

const HintBoxStyles = (theme: Theme) => createStyles({
    root: {
        boxShadow: 'none'
    },
    background: {
        height: '100%',
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    split: {
        '& .MuiFormLabel-root': {
            fontSize: '0.75rem',
            lineHeight: 1,
            whiteSpace: 'wrap',
        }
    },
    '@media screen and (min-width: 800px)': {
        split: {
            ...theme.grid.twoColumns,
            paddingTop: theme.spacing(6),
        },
    },
    input: {
        '& .Mui-disabled': {
            color: theme.palette.text.primary,
            '-webkit-text-fill-color': theme.palette.text.primary,
            '&:before': {
                borderBottomStyle: 'solid',
            }
        }
    },
});

interface HintBoxProps extends WithStyles<typeof HintBoxStyles>, WithTranslation {
    className?: string,
    norm: Norm2018 | Norm2022
}

interface HintBoxState {

}

class HintBox extends React.Component<HintBoxProps, HintBoxState> {

    render() {
        const classes = this.props.classes;

        let minArea = this.props.t('results.hint.notAllowed');
        let allowedAndReachable = false;
        if (this.props.norm.calcMinArea() <= (this.props.norm.location.area.max || 0) && this.props.norm.isMinAreaReachable()) {
            minArea = JSON.stringify(round(this.props.norm.calcMinArea(), 'ceil'));
            allowedAndReachable = true;
        }

        return (
            <Paper square={true} className={classes.root + ' ' + this.props.className}>
                <CardContent className={classes.background}>
                    <Box>
                        <Typography variant="h3">
                            {this.props.t('results.hint.headline')}
                        </Typography>
                        <Typography>
                            {this.props.t('results.hint.bodytext')}
                        </Typography>
                        <Typography component="div" className={classes.split}>
                            <TextField
                                className={classes.input}
                                variant="standard"
                                label={this.props.t('results.hint.minArea')}
                                disabled
                                value={minArea}
                                InputProps={allowedAndReachable ? {
                                    endAdornment: <span>m²</span>
                                } : {}}
                            />
                            <TextField
                                className={classes.input}
                                variant="standard"
                                label={this.props.t('results.hint.maxRefrigerantAmount')}
                                disabled
                                value={round(this.props.norm.calcMaxRefrigerantAmountForHint(), 'floor')}
                                InputProps={{
                                    endAdornment: <span>kg</span>
                                }}
                            />
                        </Typography>
                    </Box>
                </CardContent>
            </Paper>
        );
    }
}

const styledHintBox = withStyles(HintBoxStyles)(HintBox);

export default withTranslation()(styledHintBox);