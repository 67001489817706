import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import {BrowserRouter as Router} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import {theme} from "./Configurations";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


Sentry.init({
    dsn: "https://08917754b61c44e2969b650ae3473e06@sentry.cvc.digital/11",
});

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: require('./locales/en/translation.json')},
            de: { translation: require('./locales/de/translation.json')},
            nl: { translation: require('./locales/nl/translation.json')},
            fr: { translation: require('./locales/fr/translation.json')},
        },
        supportedLngs: ['en', 'de', 'nl', 'fr'],
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });

if(navigator && navigator.language){
    i18n.changeLanguage(navigator.language.slice(0,2));
} else {
    i18n.changeLanguage('en');
}

serviceWorkerRegistration.register();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Router>
                <App/>
            </Router>
        </ThemeProvider>
    </StyledEngineProvider>
);
