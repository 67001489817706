import MinMaxValue from "../DataObjects/MinMaxValue";
import {getEmptyStringOr} from "../Helper";

export default class LocationData {
    private _roomCutEven: boolean;
    private _area: MinMaxValue;
    private _height: MinMaxValue;
    private _volume: MinMaxValue;
    private _basement: boolean;

    constructor(
        roomCutEven: boolean = true,
        area: MinMaxValue = new MinMaxValue(0, 250),
        height: MinMaxValue = new MinMaxValue(0, 2.20),
        volume: MinMaxValue = new MinMaxValue(0, 550),
        basement: boolean = false
    ) {
        this._roomCutEven = roomCutEven;
        this._area = area;
        this._height = height;
        this._volume = volume;
        this._basement = basement;
    }

    get roomCutEven(): boolean {
        return this._roomCutEven;
    }

    set roomCutEven(value: boolean) {
        this._roomCutEven = value;
    }

    get area(): MinMaxValue {
        return this._area;
    }

    set area(value: MinMaxValue|number) {
        if (value instanceof MinMaxValue) {
            this._area = value;
        } else {
            this._area.value = value;
        }
    }

    get height(): MinMaxValue {
        return this._height;
    }

    set height(value: MinMaxValue|number) {
        if (value instanceof MinMaxValue) {
            this._height = value;
        } else {
            this._height.value = value;
        }
    }

    get volume(): MinMaxValue {
        return this._volume;
    }

    set volume(value: MinMaxValue|number) {
        if (value instanceof MinMaxValue) {
            this._volume = value;
        } else {
            this._volume.value = value;
        }
    }

    get basement(): boolean {
        return this._basement;
    }

    set basement(value: boolean) {
        this._basement = value;
    }

    getData() {
        return {
            roomCutEven: this.roomCutEven,
            area: this.area.value,
            height: this.height.value,
            volume: this.volume.value,
            basement: this.basement,

            valid: {
                area: this.area.validValue,
                height: this.height.validValue,
                volume: this.volume.validValue,
            }
        }
    }

    setData(data: any = {}) {
        Object.keys(data).map((key: string) => {
            switch (key) {
                case 'roomCutEven':
                    this.roomCutEven = data[key];
                    break;
                case 'area':
                    this.area = data[key];
                    break;
                case 'height':
                    this.height = data[key];
                    break;
                case 'volume':
                    this.volume = data[key];
                    break;
                case 'basement':
                    this.basement = data[key];
                    break;
            }
            return key;
        });

        if (!this.roomCutEven && this.area.value !== 0 && this.volume.value !== 0) {
            this.height = new MinMaxValue(this.height.min, this.height.max, parseFloat((this.volume.validValue / this.area.validValue).toFixed(2)));
        } else if (this.roomCutEven && this.area.value !== 0 && this.height.value !== 0) {
            this.volume = new MinMaxValue(this.volume.min, this.volume.max, parseFloat((this.height.validValue * this.area.validValue).toFixed(2)));
        }
    }

    /* * * * * *
     * Helpers *
     * * * * * */

    public isReady() {
        switch(this.roomCutEven) {
            case true:
                return getEmptyStringOr(this.area.value) !== '' &&
                    getEmptyStringOr(this.height.value) !== '';
            case false:
                return getEmptyStringOr(this.area.value) !== '' &&
                    getEmptyStringOr(this.volume.value) !== '';
        }
    }
}