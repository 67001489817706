import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const ValveStyles = (theme: Theme) => createStyles({
    a: {
        strokeMiterlimit: 10
    },
    b: {
        strokeMiterlimit: 10,
        fill: 'none !important'
    }
});

interface ValveProps extends WithStyles<typeof ValveStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface ValveState {
    color: string,
    width: number,
    height: number,
}

class Valve extends React.Component<ValveProps, ValveState> {
    constructor(props: ValveProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 20,
            height: this.props.height ? this.props.height : 18,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.639 20.604">
                <g transform="translate(1171.5 -769.956)">
                    <g transform="translate(-1171 770.456)">
                        <line className={classes.a} fill={this.state.color} stroke={this.state.color} x2="2.72"
                              y2="2.72" transform="translate(6.347 1.867)"/>
                        <line className={classes.a} fill={this.state.color} stroke={this.state.color} x2="2.72"
                              y2="2.72" transform="translate(12.582 8.102)"/>
                        <line className={classes.a} fill={this.state.color} stroke={this.state.color} x1="2.709"
                              y2="2.709" transform="translate(12.582 1.867)"/>
                        <line className={classes.a} fill={this.state.color} stroke={this.state.color} x1="2.709"
                              y2="2.709" transform="translate(6.347 8.102)"/>
                        <circle className={classes.b} stroke={this.state.color} cx="2.493" cy="2.493" r="2.493"
                                transform="translate(8.326 3.846)"/>
                        <circle className={classes.b} stroke={this.state.color} cx="6.339" cy="6.339" r="6.339"
                                transform="translate(4.48 0)"/>
                        <path className={classes.b} stroke={this.state.color}
                              d="M-412.867,1074.237h-3.841c-.353,0-.639.5-.639,1.115V1088"
                              transform="translate(417.347 -1067.898)"/>
                        <path className={classes.b} stroke={this.state.color}
                              d="M-245.112,1074.237h3.841c.353,0,.639.5.639,1.115V1088"
                              transform="translate(262.271 -1067.898)"/>
                    </g>
                </g>
            </svg>
        );
    }
}

const styledValve = withStyles(ValveStyles)(Valve);

export default withTranslation()(styledValve);