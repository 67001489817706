export default function round(float: number, method: string = 'cut') {
    switch(method) {
        case 'floor':
            return Math.floor(float * 100) / 100;
        case 'ceil':
            return Math.ceil(float * 100) / 100;
        case 'cut':
        default:
            return parseFloat(float.toFixed(2));
    }

}
