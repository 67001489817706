import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const CheckStyles = (theme: Theme) => createStyles({
    a: {
        strokeWidth: 10,
        fill: 'none !important'
    },
    b: {
        fillRule: 'evenodd'
    }
});

interface CheckProps extends WithStyles<typeof CheckStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface CheckState {
    color: string,
    width: number,
    height: number,
}

class Check extends React.Component<CheckProps, CheckState> {
    constructor(props: CheckProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 30,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.69 180.68">
                <g transform="translate(-1305 -634.32)">
                    <ellipse className={classes.a} stroke={this.state.color} cx="85.345" cy="85.34" rx="85.345" ry="85.34"
                             transform="translate(1310 639.32)"/>
                    <path className={classes.b} fill={this.state.color}
                          d="M32.036,55.241,10.559,35.572,3.4,42.129,32.036,68.354,93.4,12.156,86.241,5.6Z"
                          transform="translate(1346.6 686.398)"/>
                </g>
            </svg>
        );
    }
}

const styledCheck = withStyles(CheckStyles)(Check);

export default withTranslation()(styledCheck);