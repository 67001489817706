import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const ToolsStyles = (theme: Theme) => createStyles({
    a: {
        strokeWidth: 0.5
    },
});

interface ToolsProps extends WithStyles<typeof ToolsStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface ToolsState {
    color: string,
    width: number,
    height: number,
}

class Tools extends React.Component<ToolsProps, ToolsState> {
    constructor(props: ToolsProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 30,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.501 20.604">
                <g transform="translate(0.25 0.25)">
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M-1731.79,1308.273a.185.185,0,0,0,.129.054.184.184,0,0,0,.13-.054l1.89-1.891a.184.184,0,0,0,0-.259l-4.263-4.264a.183.183,0,0,0-.13-.053h-3.781a.185.185,0,0,0-.169.113.184.184,0,0,0,.04.2Zm-2.319-6.1,4.079,4.08-1.631,1.631-5.711-5.711Z"
                          transform="translate(1745.124 -1301.807)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M-1736.033,1309.58a.182.182,0,0,0-.054-.129l-.875-.87,1.806-1.795-.26-.259-1.8,1.8-.875-.87a.184.184,0,0,0-.259,0l-6.493,6.457a1.58,1.58,0,0,0-.468,1.127,1.58,1.58,0,0,0,.468,1.129,1.6,1.6,0,0,0,1.134.465,1.6,1.6,0,0,0,1.134-.465l6.494-6.458A.183.183,0,0,0-1736.033,1309.58Zm-6.808,6.329a1.275,1.275,0,0,1-1.749,0,1.218,1.218,0,0,1-.361-.871,1.216,1.216,0,0,1,.361-.869l6.364-6.329,1.75,1.739Z"
                          transform="translate(1745.318 -1301.778)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M-1725.49,1309.12a1.608,1.608,0,0,0-2.27,0l-6.493,6.5a.183.183,0,0,0,0,.259l2.008,2.009a.184.184,0,0,0,.13.054.183.183,0,0,0,.13-.054l6.495-6.5a1.591,1.591,0,0,0,.469-1.134A1.594,1.594,0,0,0-1725.49,1309.12Zm-.259,2.009h0l-6.364,6.364-1.75-1.749,6.364-6.365a1.242,1.242,0,0,1,1.75,0,1.231,1.231,0,0,1,.362.875A1.229,1.229,0,0,1-1725.749,1311.13Z"
                          transform="translate(1745.023 -1301.815)"/>
                    <rect className={classes.a} fill={this.state.color} stroke={this.state.color} width="5.425"
                          height="0.367"
                          transform="translate(6.948 19.741) rotate(-45)"/>
                </g>
            </svg>
        );
    }
}

const styledTools = withStyles(ToolsStyles)(Tools);

export default withTranslation()(styledTools);