import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';
import {TextValidator} from 'react-material-ui-form-validator';

import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';

import SectionCard from '../../Partials/SectionCard';
import ToolsSVG from '../../SVGs/Tools';
import {getEmptyStringOr} from "../../../Helper";
import {Norm2018, Norm2022} from "../../../Norms";
import LocationData from "../../../FormData/LocationData";

const LocationStyles = (theme: Theme) => createStyles({
    root: {},
    disabled: {
        opacity: 0.5
    },
    locationCut: {},
    cut: {
        display: 'grid',
        gridTemplateRows: '90px 1fr',
        background: '#E8E5DE',
        margin: theme.spacing(4) + ' 0',
        padding: theme.spacing(6),
        paddingBottom: theme.spacing(3),
        textAlign: 'center',
        '& img': {
            height: '100%',
            width: 'auto',
            margin: '0 auto'
        },
        '& .MuiFormControlLabel-root': {
            display: 'inline-block',
            margin: 0
        },
        '& .MuiFormControlLabel-label': {
            display: 'block'
        },
        '&.disabled': {
            background: 'transparent'
        }
    },
    checkbox: {
        border: '1px solid ' + theme.palette.secondary.contrastText,
        width: 30,
        height: 30
    },
    checkedCheckbox: {
        border: '1px solid ' + theme.palette.secondary.contrastText,
        background: theme.palette.secondary.contrastText,
        width: 30,
        height: 30
    },
    '@media screen and (min-width: 800px)': {
        locationCut: {
            ...theme.grid.twoColumns,
            gridGap: theme.spacing(15)
        },
    }
});

interface LocationProps extends WithStyles<typeof LocationStyles>, WithTranslation {
    norm: Norm2018|Norm2022,
    updateNorm: any,
    disabled: boolean
}

interface LocationState {

}

class Location extends React.Component<LocationProps, LocationState> {
    handleNumberChange = (key: keyof LocationData, event: any) => {
        const value = event.currentTarget.value;

        this.props.updateNorm({[key]: value.replace(/[^0-9,.]/g, "").replace(",", ".")});
    };

    handleCheckedChange = (key: string, event: any) => {
        if (key === 'even') {
            this.props.updateNorm({roomCutEven: true});
        } else if (key === 'odd') {
            this.props.updateNorm({roomCutEven: false});
        } else {
            this.props.updateNorm({[key]: event.currentTarget.checked});
        }
    };

    render() {
        const classes = this.props.classes;
        const classEvenly = classes.cut + (!this.props.norm.location.roomCutEven ? ' disabled' : '');
        const classOddly = classes.cut + (this.props.norm.location.roomCutEven ? ' disabled' : '');

        return (
            <SectionCard disabled={this.props.disabled}
                         avatar={<ToolsSVG/>}
                         title={<Typography variant="h2">{this.props.t('location.headline')}</Typography>}
            >
                <Typography>{this.props.t('location.cut.headline')}</Typography>
                <Box className={classes.locationCut}>
                    <Box className={!this.props.norm.location.roomCutEven ? classes.disabled : ''}>
                        <Box className={classEvenly}>
                            <img src={require('../../../images/room-even.svg').default} alt="Room even"/>
                            <FormControlLabel
                                label={this.props.t('location.cut.evenly')}
                                control={<Checkbox icon={<div className={classes.checkbox}/>}
                                                   checkedIcon={
                                                       <div className={classes.checkedCheckbox}>
                                                           <Check color="secondary"/>
                                                       </div>}
                                                   color="default"/>}
                                labelPlacement="end"
                                checked={this.props.norm.location.roomCutEven}
                                onChange={this.handleCheckedChange.bind(this, "even")}
                                disabled={this.props.disabled}
                            />
                        </Box>
                        {/* @ts-ignore */}
                        <TextValidator
                            variant="standard"
                            label={this.props.t('location.dimensions.area')}
                            name="area"
                            type="text"
                            InputProps={{
                                endAdornment: <span>m²</span>
                            }}
                            value={getEmptyStringOr(this.props.norm.location.area.value)}
                            onChange={this.handleNumberChange.bind(this, "area")}
                            validators={[
                                'matchRegexp:^\\d+([\\.,]\\d{1,2})?$',
                                'minFloat:' + this.props.norm.location.area.min,
                                'maxFloat:' + this.props.norm.location.area.max
                            ]}
                            errorMessages={[
                                this.props.t('error.number'),
                                this.props.t('error.min', {minimum: this.props.norm.location.area.min}),
                                this.props.t('error.max', {maximum: this.props.norm.location.area.max})
                            ]}
                            disabled={!this.props.norm.location.roomCutEven || this.props.disabled}
                        />
                        {/* @ts-ignore */}
                        {this.props.norm instanceof Norm2018 && <TextValidator
                            variant="standard"
                            label={this.props.t('location.dimensions.height')}
                            name="height"
                            type="text"
                            InputProps={{
                                endAdornment: <span>m</span>
                            }}
                            value={getEmptyStringOr(this.props.norm.location.height.value)}
                            onChange={this.handleNumberChange.bind(this, "height")}
                            validators={[
                                'matchRegexp:^\\d+([\\.,]\\d{1,2})?$',
                                'minFloat:' + this.props.norm.location.height.min,
                                'maxFloat:' + this.props.norm.location.height.max,
                                'minFloat:' + this.props.norm.system.installationHeight.value,
                            ]}
                            errorMessages={[
                                this.props.t('error.number'),
                                this.props.t('error.min', {minimum: this.props.norm.location.height.min}),
                                this.props.t('error.max', {maximum: this.props.norm.location.height.max}),
                                this.props.t('error.installationHeight')
                            ]}
                            disabled={!this.props.norm.location.roomCutEven || this.props.disabled}
                        />}
                        {/* @ts-ignore */}
                        {this.props.norm instanceof Norm2022 && <TextValidator
                            variant="standard"
                            label={this.props.t('location.dimensions.height')}
                            name="height"
                            type="text"
                            InputProps={{
                                endAdornment: <span>m</span>
                            }}
                            value={getEmptyStringOr(this.props.norm.location.height.value)}
                            onChange={this.handleNumberChange.bind(this, "height")}
                            validators={[
                                'matchRegexp:^\\d+([\\.,]\\d{1,2})?$',
                                'minFloat:' + this.props.norm.location.height.min,
                                'maxFloat:' + this.props.norm.location.height.max,
                                'minFloat:' + this.props.norm.system.exitHeight.value,
                            ]}
                            errorMessages={[
                                this.props.t('error.number'),
                                this.props.t('error.min', {minimum: this.props.norm.location.height.min}),
                                this.props.t('error.max', {maximum: this.props.norm.location.height.max}),
                                this.props.t('error.exitHeight')
                            ]}
                            disabled={!this.props.norm.location.roomCutEven || this.props.disabled}
                        />}
                    </Box>
                    <Box className={this.props.norm.location.roomCutEven ? classes.disabled : ''}>
                        <Box className={classOddly}>
                            <img src={require('../../../images/room-odd.svg').default} alt="Room odd"/>
                            <FormControlLabel
                                label={this.props.t('location.cut.oddly')}
                                control={<Checkbox icon={<div className={classes.checkbox}/>}
                                                   checkedIcon={
                                                       <div className={classes.checkedCheckbox}>
                                                           <Check color="secondary"/>
                                                       </div>}
                                                   color="default"/>}
                                labelPlacement="end"
                                checked={!this.props.norm.location.roomCutEven}
                                onChange={this.handleCheckedChange.bind(this, "odd")}
                                disabled={this.props.disabled}
                            />
                        </Box>
                        {/* @ts-ignore */}
                        <TextValidator
                            variant="standard"
                            label={this.props.t('location.dimensions.area')}
                            name="area"
                            type="text"
                            InputProps={{
                                endAdornment: <span>m²</span>
                            }}
                            value={getEmptyStringOr(this.props.norm.location.area.value)}
                            onChange={this.handleNumberChange.bind(this, "area")}
                            validators={[
                                'matchRegexp:^\\d+([\\.,]\\d{1,2})?$',
                                'minFloat:' + this.props.norm.location.area.min,
                                'maxFloat:' + this.props.norm.location.area.max
                            ]}
                            errorMessages={[
                                this.props.t('error.number'),
                                this.props.t('error.min', {minimum: this.props.norm.location.area.min}),
                                this.props.t('error.max', {maximum: this.props.norm.location.area.max})
                            ]}
                            disabled={this.props.norm.location.roomCutEven || this.props.disabled}
                        />
                        {/* @ts-ignore */}
                        <TextValidator
                            variant="standard"
                            label={this.props.t('location.dimensions.volume')}
                            name="volume"
                            type="text"
                            InputProps={{
                                endAdornment: <span>m³</span>
                            }}
                            onChange={this.handleNumberChange.bind(this, "volume")}
                            value={getEmptyStringOr(this.props.norm.location.volume.value)}
                            validators={[
                                'matchRegexp:^\\d+([\\.,]\\d{1,2})?$',
                                'minFloat:' + this.props.norm.location.volume.min,
                                'maxFloat:' + this.props.norm.location.volume.max
                            ]}
                            errorMessages={[
                                this.props.t('error.number'),
                                this.props.t('error.min', {minimum: this.props.norm.location.volume.min}),
                                this.props.t('error.max', {maximum: this.props.norm.location.volume.max})
                            ]}
                            disabled={this.props.norm.location.roomCutEven || this.props.disabled}
                        />
                    </Box>
                </Box>
                <FormControlLabel
                    label={this.props.t('location.location.basement')}
                    control={<Checkbox icon={<div className={classes.checkbox}/>}
                                       checkedIcon={
                                           <div className={classes.checkedCheckbox}>
                                               <Check color="secondary"/>
                                           </div>}
                                       color="default"/>}
                    labelPlacement="end"
                    checked={this.props.norm.location.basement}
                    onChange={this.handleCheckedChange.bind(this, "basement")}
                    disabled={this.props.disabled}
                />
            </SectionCard>
        );
    }
}

const styledLocation = withStyles(LocationStyles)(Location);

export default withTranslation()(styledLocation);