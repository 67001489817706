import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SectionCard from '../Partials/SectionCard';
import NormSVG from '../SVGs/Norm';
import {Norm2018, Norm2022} from "../../Norms";

const NormStyles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(4) + ' auto'
    },
    button: {
        marginTop: theme.spacing(6),
        marginRight: theme.spacing(6)
    }
});

interface NormProps extends WithStyles<typeof NormStyles>, WithTranslation {
    setNorm: any
}

interface NormState {

}

class Norm extends React.Component<NormProps, NormState> {
    render() {
        const classes = this.props.classes;

        return (
            <SectionCard rootClass={classes.root}
                         avatar={<NormSVG/>}
                         title={<Typography variant="h2">{this.props.t('norm.headline')}</Typography>}>
                <Typography dangerouslySetInnerHTML={{__html: this.props.t('norm.bodytext')}}/>
                <Button
                    className={classes.button}
                    variant="contained" color="primary"
                    onClick={this.props.setNorm.bind(this, new Norm2018())}>
                    {this.props.t('norm.button.norm_2018')}
                </Button>
                <Button
                    className={classes.button}
                    variant="contained" color="primary"
                    onClick={this.props.setNorm.bind(this, new Norm2022())}>
                    {this.props.t('norm.button.norm_2022')}
                </Button>
            </SectionCard>
        );
    }
}

const styledNorm = withStyles(NormStyles)(Norm);

export default withTranslation()(styledNorm);