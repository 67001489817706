import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {Norm2018, Norm2022} from "../../Norms";

const Diagram = React.lazy(() => import("../Partials/Diagram"));

const DiagramBoxStyles = (theme: Theme) => createStyles({
    root: {
        boxShadow: 'none'
    },
    diagramHeadline: {
        paddingLeft: '8.2%'
    },
    caption: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(1),
        paddingLeft: '8.2%',
        paddingBottom: theme.spacing(6)
    },
    description: {
        position: 'relative',
        paddingLeft: theme.spacing(3),
        alignSelf: 'center',
        fontSize: 8,
        color: theme.palette.secondary.contrastText,
        '&:before': {
            content: `''`,
            width: 10,
            height: 10,
            position: 'absolute',
            left: 0,
            bottom: '5'
        }
    },
    z0: {'&:before': {background: theme.palette.z0.main}},
    z1: {'&:before': {background: theme.palette.z1.main}},
    z2: {'&:before': {background: theme.palette.z2.main}},
    z3: {'&:before': {background: theme.palette.z3.main}},
    deadZone: {'&:before': {background: theme.palette.deadZone.main}},
    toxicZone: {'&:before': {background: theme.palette.toxicZone.main}},
});

interface DiagramBoxProps extends WithStyles<typeof DiagramBoxStyles>, WithTranslation {
    className?: string,
    norm: Norm2018|Norm2022,
    updateNorm: any
}

interface DiagramBoxState {

}

class DiagramBox extends React.Component<DiagramBoxProps, DiagramBoxState> {

    render() {
        const classes = this.props.classes;

        return (
            <Box className={classes.root + ' ' + this.props.className}>
                <Typography variant="h3" className={classes.diagramHeadline}>
                    {this.props.t('results.diagram.headline')}
                </Typography>
                <Diagram norm={this.props.norm} updateNorm={this.props.updateNorm.bind(this)}/>
                {this.props.norm instanceof Norm2018 && <Box className={classes.caption}>
                    <Typography component="div" className={classes.description + ' ' + classes.z0}>
                        {this.props.t('results.diagram2018.z0')}
                    </Typography>
                    <Typography component="div" className={classes.description + ' ' + classes.z1}>
                        {this.props.t('results.diagram2018.z1')}
                    </Typography>
                    <Typography component="div" className={classes.description + ' ' + classes.deadZone}>
                        {this.props.t('results.diagram2018.deadZone')}
                    </Typography>
                    <Typography component="div" className={classes.description + ' ' + classes.z2}>
                        {this.props.t('results.diagram2018.z2')}
                    </Typography>
                    <Typography component="div" className={classes.description + ' ' + classes.toxicZone}>
                        {this.props.t('results.diagram2018.toxicZone')}
                    </Typography>
                    <Typography component="div" className={classes.description + ' ' + classes.z3}>
                        {this.props.t('results.diagram2018.z3')}
                    </Typography>
                </Box>}
                {this.props.norm instanceof Norm2022 && <Box className={classes.caption}>
                    <Typography component="div" className={classes.description + ' ' + classes.deadZone}>
                        {this.props.t('results.diagram2022.deadZone')}
                    </Typography>
                    <Typography component="div" className={classes.description + ' ' + classes.z0}>
                        {this.props.t('results.diagram2022.z1')}
                    </Typography>
                    <Typography component="div" className={classes.description + ' ' + classes.toxicZone}>
                        {this.props.t('results.diagram2022.toxicZone')}
                    </Typography>
                    <Typography component="div" className={classes.description + ' ' + classes.z2}>
                        {this.props.t('results.diagram2022.z2')}
                    </Typography>
                    <Typography component="div"/>
                    <Typography component="div" className={classes.description + ' ' + classes.z3}>
                        {this.props.t('results.diagram2022.z3')}
                    </Typography>
                </Box>}
            </Box>
        );
    }
}

const styledDiagramBox = withStyles(DiagramBoxStyles)(DiagramBox);

export default withTranslation()(styledDiagramBox);