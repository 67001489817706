import {
    AbstractSystemData,
    ChillerSystemData, EcodanSystemData,
    HvrfSystemData,
    MSerieSystemData,
    PacIfSystemData,
    SMextSystemData,
    VrfSystemData
} from "../FormData/SystemData";
import LocationData from "../FormData/LocationData";
import MetaData from "../FormData/MetaData";
import {AmountUnit, Multipliers, RefrigerantData} from "../DataObjects";
import {getEmptyStringOr, round} from "../Helper";

export default class Norm2018 {
    private _system: AbstractSystemData;
    private _location: LocationData;
    private _meta: MetaData;
    private _diagram: string;

    static get identifier() {
        return '2018';
    }

    get identifier() {
        return Norm2018.identifier;
    }

    static get series() {
        return [
            'ecodan',
            'mSerie',
            'mrSlim',
            'vrf',
            'hvrf',
            'pacIf',
            'chiller',
            'sMext'
        ];
    }

    get series() {
        return Norm2018.series;
    }

    static get refrigerants() {
        return [
            new RefrigerantData(
                'r32',
                new AmountUnit(0.307, 'kg/m³'),
                new AmountUnit(0.063, 'kg/m³'),
                new AmountUnit(0.061, 'kg/m³'),
                new AmountUnit(0.15, 'kg/m³'),
                new AmountUnit(0.3, 'kg/m³')
            ),
            new RefrigerantData(
                'r1234ze',
                new AmountUnit(0.304, 'kg/m³'),
                new AmountUnit(0.063, 'kg/m³'),
                new AmountUnit(0.061, 'kg/m³'),
                new AmountUnit(0.15, 'kg/m³'),
                new AmountUnit(0.28, 'kg/m³')
            ),
            new RefrigerantData(
                'r454b',
                new AmountUnit(0.354, 'kg/m³'),
                new AmountUnit(0.0885, 'kg/m³'),
                new AmountUnit(0.0885, 'kg/m³'),
                new AmountUnit(0.177, 'kg/m³'),
                new AmountUnit(0.435, 'kg/m³')
            ),
        ];
    }

    get refrigerants() {
        return Norm2018.refrigerants;
    }

    constructor(
        system: AbstractSystemData = new EcodanSystemData(),
        location: LocationData = new LocationData(),
        meta: MetaData = new MetaData(),
        diagram: string = '',
    ) {
        this._system = system;
        this._system.normIdentifier = Norm2018.identifier;
        this._location = location;
        this._meta = meta;
        this._diagram = diagram;
    }

    get system(): AbstractSystemData {
        return this._system;
    }

    set system(value: AbstractSystemData) {
        this._system = value;
    }

    get location(): LocationData {
        return this._location;
    }

    set location(value: LocationData) {
        this._location = value;
    }

    get meta(): MetaData {
        return this._meta;
    }

    set meta(value: MetaData) {
        this._meta = value;
    }

    get multipliers(): Multipliers {
        switch (true) {
            case (this.system instanceof ChillerSystemData):
                return new Multipliers(
                    39 * this.system.refrigerant.lfl.amount,
                    this.location.basement ? this.system.refrigerant.rcl.amount : this.system.refrigerant.qlmv.amount,
                    this.system.refrigerant.qlav.amount,
                    0
                );
            case (this.system instanceof HvrfSystemData):
                return new Multipliers(
                    39 * this.system.refrigerant.lfl.amount,
                    this.location.basement ? this.system.refrigerant.rcl.amount : this.system.refrigerant.qlmv.amount,
                    this.system.refrigerant.qlav.amount,
                    52 * this.system.refrigerant.lfl.amount
                );
            case (this.system instanceof SMextSystemData):
                return new Multipliers(
                    0,
                    0,
                    0,
                    0
                );
            case (this.system instanceof VrfSystemData):
                return new Multipliers(
                    52 * this.system.refrigerant.lfl.amount,
                    this.system.numberOfUnits >= 4 ? this.system.refrigerant.rcl.amount : 0.25 * this.system.refrigerant.lfl.amount,
                    0.5 * this.system.refrigerant.lfl.amount,
                    52 * this.system.refrigerant.lfl.amount
                );
            default:
                return new Multipliers(
                    52 * this.system.refrigerant.lfl.amount,
                    0.25 * this.system.refrigerant.lfl.amount,
                    0.5 * this.system.refrigerant.lfl.amount,
                    52 * this.system.refrigerant.lfl.amount
                );
        }
    }

    get diagram(): string {
        return this._diagram;
    }

    set diagram(value: string) {
        this._diagram = value;
    }

    getData() {
        return {
            ...this.system.getData(),
            ...this.location.getData(),
            ...this.meta.getData(),

            diagram: this.diagram,

            // calculation
            minArea: round(this.calcMinArea(), 'ceil'),
            maxRefrigerantAmount: round(this.calcMaxRefrigerantAmount(), 'floor'),
            deadZone: this.calcBreakpointDeadZone(),
            zone: this.calcZone(),
            numberOfSafetyMeasures: this.calcNumberOfSafetyMeasures(),

            // results
            minAreaReachable: this.isMinAreaReachable(),
            installationPossible: this.isInstallationPossible(),
            circulationNecessary: this.isCirculationNecessary(),
            toxicityNecessary: this.isToxicityHandlingNecessary(),
            necessaryMeasures: this.getNecessaryMeasures(),

            valid: {
                ...this.system.getData().valid,
                ...this.location.getData().valid,
            },

            show: {
                hint: this.showHint(),
                measures: this.showMeasures(),
                diagram: this.showDiagram()
            }
        }
    }

    setData(data: any = {}) {
        this.system.setData(data);
        this.location.setData(data);
        this.meta.setData(data);

        Object.keys(data).map((key: string) => {
            switch (key) {
                case 'diagram':
                    this.diagram = data[key];
                    break;
            }
            return key;
        });
    }

    /* * * * * * * *
     * Calculation *
     * * * * * * * */

    calcMinArea(
        lfl: number = this.system.refrigerant.lfl.amount,
        installationHeight: number = this.system.installationHeight.validValue,
        refrigerantAmount: number = this.system.refrigerantAmount.validValue,
    ): number {
        switch (true) {
            case (this.system instanceof MSerieSystemData && this.system.floorUnit):
                return refrigerantAmount / (0.75 * lfl * 2.2);
            default:
                const z0 = Math.pow(refrigerantAmount / (2.5 * installationHeight * Math.pow(lfl, 1.25)), 2);

                let z1 = 999;
                if (this.calcNumberOfSafetyMeasures(1) === 0) {
                    z1 = refrigerantAmount / (this.multipliers.breakpointOneTwo * this.location.height.validValue);
                }

                let z2 = 999;
                if (this.calcNumberOfSafetyMeasures(2) === 0) {
                    z1 = refrigerantAmount / (this.multipliers.breakpointTwoThree * this.location.height.validValue);
                }

                return Math.min(z0, z1, z2);
        }
    }

    calcPlainMaxRefrigerantAmount(
        lfl: number = this.system.refrigerant.lfl.amount,
        installationHeight: number = this.system.installationHeight.validValue,
        area: number = this.location.area.validValue,
    ): number {
        switch (true) {
            case (this.system instanceof MSerieSystemData && this.system.floorUnit):
                return 0.75 * lfl * 2.2 * area;
            default:
                return 2.5 * Math.pow(lfl, 1.25) * installationHeight * Math.pow(area, 0.5);
        }
    }

    calcMaxRefrigerantAmount(
        lfl: number = this.system.refrigerant.lfl.amount,
        installationHeight: number = this.system.installationHeight.validValue,
        area: number = this.location.area.validValue,
        calcSmallest = false,
    ): number {
        switch (true) {
            case (this.system instanceof PacIfSystemData && !this.system.inBuilding && !calcSmallest):
                return this.calcBreakpointDeadZone();
            default:
                let maxRefrigerantAmount = this.calcPlainMaxRefrigerantAmount(lfl, installationHeight, area);
                if (maxRefrigerantAmount <= 6 * lfl) {
                    maxRefrigerantAmount = 6 * lfl;
                } else if (maxRefrigerantAmount > this.multipliers.maxRefrigerantAmount) {
                    maxRefrigerantAmount = this.multipliers.maxRefrigerantAmount;
                }

                return maxRefrigerantAmount;
        }
    }

    calcMaxRefrigerantAmountForHint(): number {
        let z0 = this.calcMaxRefrigerantAmount();

        let z1 = 0;
        if (this.calcNumberOfSafetyMeasures(1) === 0) {
            z1 = this.calcBreakpointOneTwo(this.location.volume.validValue);
        }

        let z2 = 0;
        if (this.calcNumberOfSafetyMeasures(2) === 0) {
            z2 = this.calcBreakpointTwoThree(this.location.volume.validValue);
        }

        return Math.max(z0, z1, z2);
    }

    calcBreakpointOneTwo(
        volume: number = this.location.volume.validValue,
    ): number {
        return this.multipliers.breakpointOneTwo * volume;
    }

    get maxBreakpointOneTwo(): number {
        return this.calcBreakpointOneTwo(this.location.volume.max);
    }

    calcBreakpointTwoThree(
        volume: number = this.location.volume.validValue,
    ): number {
        return this.multipliers.breakpointTwoThree * volume;
    }

    get maxBreakpointTwoThree(): number {
        return this.calcBreakpointTwoThree(this.location.volume.max);
    }

    calcBreakpointDeadZone(
        numberOfUnits: number = this.system.numberOfUnits,
    ): number {
        switch (true) {
            case (this.system instanceof MSerieSystemData && this.system.floorUnit):
                return this.multipliers.breakpointDeadzone;
            default:
                return this.multipliers.breakpointDeadzone * numberOfUnits;
        }
    }

    calcZone(): number {
        if (this.system.refrigerantAmount.validValue <= this.calcMaxRefrigerantAmount()) {
            return 0;
        } else if (this.system.refrigerantAmount.validValue <= this.calcBreakpointOneTwo()) {
            return 1;
        } else if (this.system.refrigerantAmount.validValue <= this.calcBreakpointTwoThree()) {
            return 2;
        } else {
            return 3;
        }
    }

    calcNumberOfSafetyMeasures(
        zone: number = this.calcZone()
    ): number {
        switch (zone) {
            case 2:
                if (this.location.basement) {
                    return 2;
                } else {
                    return 1;
                }
            case 3:
                if (this.location.basement) {
                    return 999;
                } else {
                    return 2;
                }
            default:
                return 0;
        }
    }

    calcToxicityMaxRefrigerantAmount(): number {
        return 6 * this.system.refrigerant.lfl.amount;
    }

    calcToxicityMaxVolume(): number {
        return this.calcToxicityMaxRefrigerantAmount() / this.system.refrigerant.atel.amount;
    }

    calcToxicityMaxArea(): number {
        return this.calcToxicityMaxVolume() / this.location.height.validValue;
    }

    calcMaxPersons(): number {
        return Math.ceil(this.location.area.validValue / 10);
    }

    /* * * * * *
     * Results *
     * * * * * */

    isMinAreaReachable(): boolean {
        return this.system.refrigerantAmount.validValue < this.calcMaxRefrigerantAmount(
            this.system.refrigerant.lfl.amount,
            this.system.installationHeight.validValue,
            this.location.area.max,
        );
    }

    isInstallationPossible(): boolean {
        switch (true) {
            case (this.system instanceof ChillerSystemData):
                if (this.system.refrigerantAmount.validValue > this.calcMaxRefrigerantAmount()) {
                    return false;
                }

                return this.calcNumberOfSafetyMeasures() !== 999;
            case (this.system instanceof SMextSystemData):
                switch (this.system instanceof SMextSystemData && this.system.combination) {
                    case 'combi006':
                        return this.location.area.validValue >= 8;
                    case 'combi009':
                    case 'combi013':
                        return this.location.area.validValue >= 15;
                    case 'combi022':
                    case 'combi038':
                    case 'combi044':
                        return this.location.area.validValue >= 21;
                }

                return false;
            default:
                if (this.system.refrigerantAmount.validValue > this.calcBreakpointDeadZone()) {
                    return false;
                }

                return this.calcNumberOfSafetyMeasures() !== 999;
        }
    }

    isCirculationNecessary(): boolean {
        switch (true) {
            case (this.system instanceof MSerieSystemData && this.system.floorUnit):
                return false;
            default:
                return (this.system.installationHeight.validValue < 1.8 && this.system.refrigerantAmount.validValue >= this.calcMaxRefrigerantAmount());
        }
    }

    isToxicityHandlingNecessary(): boolean {
        let necessary = this.system.refrigerantAmount.validValue / this.location.volume.validValue > this.system.refrigerant.atel.amount;
        if (this.system.refrigerantAmount.validValue > 6 * this.system.refrigerant.lfl.amount) {
            necessary = false;
        }

        return necessary;
    }

    isDetectorNecessary() {
        if (this.system instanceof PacIfSystemData) {
            return this.system.refrigerantAmount.validValue > round(this.calcMaxRefrigerantAmount(
                this.system.refrigerant.lfl.amount,
                this.system.airflowHeight.validValue,
                this.system.smallestArea.validValue,
                true,
            ), 'floor');
        }

        return false;
    }

    isSmallestToxic() {
        if (this.system instanceof PacIfSystemData) {
            if (this.isDetectorNecessary()) {
                return false;
            }

            if (this.system.refrigerantAmount.validValue > 6 * this.system.refrigerant.lfl.amount) {
                return false;
            }

            return (this.system.refrigerantAmount.validValue /
                (this.system.smallestArea.validValue * this.system.airflowHeight.validValue)
            ) > this.system.refrigerant.atel.amount;
        }
    }

    getNecessaryMeasures(): any[] {
        let measures = [];

        if (this.system instanceof SMextSystemData) {
            return [];
        }

        if (this.isInstallationPossible()) {
            if (!(this.system instanceof PacIfSystemData) || this.system.inBuilding) {
                if (this.calcNumberOfSafetyMeasures() > 0 || this.isToxicityHandlingNecessary()) {
                    measures.push({
                        name: 'standard',
                        items: [
                            'ventilation',
                            'alert',
                            'valve'
                        ],
                        necessary: this.calcNumberOfSafetyMeasures()
                    });
                }

                if (this.isCirculationNecessary()) {
                    measures.push({
                        name: 'circulation',
                        items: ['circulation']
                    });
                }
            }

            if (this.system instanceof PacIfSystemData) {
                if (this.isDetectorNecessary() || this.isSmallestToxic()) {
                    measures.push({
                        name: 'detector',
                        items: ['detector']
                    });
                }

                measures.push({
                    name: 'pacIfHint',
                    items: []
                });
            }
        }

        return measures;
    }

    /* * * * * * *
     * Templates *
     * * * * * * */

    showHint() {
        switch (true) {
            case (this.system instanceof PacIfSystemData && !this.system.inBuilding):
                return false;
            case (this.system instanceof SMextSystemData):
                return this.isInstallationPossible();
            default:
                return this.getNecessaryMeasures().length > 0;
        }
    }

    showMeasures() {
        return this.getNecessaryMeasures().length > 0;
    }

    showDiagram() {
        switch (true) {
            case (this.system instanceof PacIfSystemData && !this.system.inBuilding):
            case (this.system instanceof SMextSystemData):
                return false;
            default:
                return true;
        }
    }

    showButton() {
        switch (true) {
            case (this.system instanceof SMextSystemData && !this.isInstallationPossible()):
                return false;
            default:
                return navigator.onLine;
        }
    }

    /* * * * * *
     * Helpers *
     * * * * * */
    public isSystemReady() {
        return this.system.isReady();
    }

    public isLocationReady() {
        switch (true) {
            case (this.system instanceof PacIfSystemData && !this.system.inBuilding):
                return true;
            default:
                return this.location.isReady();
        }
    }

    public isInputError(): string | boolean {
        switch (true) {
            case (this.system instanceof SMextSystemData):
                if (getEmptyStringOr(this.location.area.value) === '' ||
                    getEmptyStringOr(this.system instanceof SMextSystemData ? this.system.combination : '') === ''
                ) {
                    return 'required';
                }

                return false;
            default:
                if (this.system instanceof PacIfSystemData) {
                    if (getEmptyStringOr(this.system.airflowHeight.value) === '' ||
                        getEmptyStringOr(this.system.smallestArea.value) === ''
                    ) {
                        return 'pac-if';
                    }

                    if (!this.system.inBuilding) {
                        if (getEmptyStringOr(this.system.installationHeight.value) === '' ||
                            getEmptyStringOr(this.system.refrigerantAmount.value) === '') {
                            return 'required';
                        } else {
                            return false;
                        }
                    }
                }

                if (getEmptyStringOr(this.location.area.value) === '' ||
                    getEmptyStringOr(this.location.volume.value) === '' ||
                    getEmptyStringOr(this.system.installationHeight.value) === '' ||
                    getEmptyStringOr(this.system.refrigerantAmount.value) === '') {
                    return 'required';
                }

                let height = parseFloat('' + this.location.height.value);
                let installationHeight = parseFloat('' + this.system.installationHeight.value);
                if (this.location.roomCutEven && height < installationHeight) {
                    return 'installationHeight';
                }

                return false;
        }
    }
}