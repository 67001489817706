import React, {ReactNode} from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import SectionCard from '../../Partials/SectionCard';
import Loader from '../../Partials/Loader';
import CalculatorSVG from '../../SVGs/Calculator';
import CloseSVG from '../../SVGs/Close';

const CheckerStyles = (theme: Theme) => createStyles({
    root: {
        transition: 'opacity 1s ease-in-out'
    },
    loadingPaper: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    alignCenter: {
        textAlign: 'center'
    },
    cardContent: {
        height: 'calc(100% - ' + theme.spacing(12) + ')',
        display: 'grid'
    },
    contentInner: {
        textAlign: 'center',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '2fr 1fr',
        gridGap: theme.spacing(6)
    },
    iconWrap: {
        minWidth: 190,
        minHeight: 190,
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        position: 'relative',
    },
    button: {
        position: 'absolute',
        bottom: '50%',
        right: '50%',
        transform: 'translate(50%, 50%)',
        width: '100%',
        height: '100%',
        '& svg': {
            maxWidth: 190,
            width: '75%',
            height: 180,
            '&:focus': {
                outline: 'none'
            }
        },
        '&:hover svg, &:focus svg': {
            filter: 'drop-shadow(0 0 20px rgba(183,188,35,0.5))'
        },
        '& .MuiTouchRipple-root': {
            display: 'none'
        }
    },
    disabled: {
        opacity: 0.5
    },
    progress: {
        position: 'absolute',
        bottom: '50%',
        right: '50%',
        transform: 'translate(50%, 50%)',
        '& .MuiCircularProgress-colorPrimary': {
            color: 'white'
        }
    },
    '@media screen and (min-width: 800px)': {
        contentInner: {
            textAlign: 'center',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
            '&>*': {
                justifySelf: 'center'
            }
        }
    },
    '@media screen and (min-width: 1400px)': {
        contentInner: {
            textAlign: 'center',
            display: 'grid',
            height: '100%',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '2fr 1fr'
        }
    }
});

interface CheckerProps extends WithStyles<typeof CheckerStyles>, WithTranslation {
    checkInput: any,
    disabled: boolean,
    loading: boolean,
    setLoading: any
}

interface CheckerState {
    error: boolean,
    errorMessage: string
}

class Checker extends React.Component<CheckerProps, CheckerState> {
    constructor(props: CheckerProps) {
        super(props);
        this.state = {
            error: false,
            errorMessage: ''
        }
    }

    render() {
        const classes = this.props.classes;

        let avatar = (!this.props.loading && !this.state.error ? <CalculatorSVG/> : null);
        let title = this.genTitle('', this.props.t('checker.headline.main'));
        if (this.props.loading) {
            title = this.genTitle(classes.alignCenter, this.props.t('checker.headline.loading'));
        }
        if (this.state.error) {
            title = this.genTitle(classes.alignCenter, this.props.t('checker.headline.error'));
        }

        let status = 'before';
        if (this.props.loading && !this.state.error) {
            status = 'loading';
        }
        if (this.props.loading && this.state.error) {
            status = 'error';
        }

        return (
            <SectionCard disabled={this.props.disabled}
                         avatar={avatar}
                         title={title}
                         rootClass={(this.props.loading || this.state.error ? ' ' + classes.loadingPaper : '')}
                         contentClass={classes.cardContent}>
                <Loader status={status}
                        before={this.getContent(
                            <Button className={classes.button}
                                    disabled={this.props.disabled}
                                    onClick={this.checkInput.bind(this)}>
                                <CalculatorSVG color="#b7bc23"/>
                            </Button>,
                            this.props.t('checker.bodytext'))}
                        loading={this.getContent(
                            <Box className={classes.progress}>
                                <CircularProgress size="190px"/>
                            </Box>,
                            this.props.t('checker.bodytext'))}
                        success={null}
                        error={this.getContent(
                            <Button className={classes.button}
                                    onClick={this.removeError.bind(this)}>
                                <CloseSVG/>
                            </Button>,
                            this.state.errorMessage)}/>
            </SectionCard>
        );
    }

    genTitle = (className: string, content: string) => {
        return <Typography variant="h2" className={className
        }>{content}</Typography>
    };

    getContent = (icon: ReactNode, text: string) => {
        const classes = this.props.classes;

        return (
            <Box className={classes.contentInner}>
                <Box className={classes.iconWrap}>
                    {icon}
                </Box>
                <Typography>
                    {text}
                </Typography>
            </Box>)
    };

    checkInput = () => {
        this.props.setLoading(true);
        let checker = this;
        let response = null;
        setTimeout(function () {
            response = checker.props.checkInput();
            if (response) {
                checker.setState({
                    error: response.error,
                    errorMessage: response.message
                });
            }
        }, 1500);
    };

    removeError = () => {
        this.props.setLoading(false);
        this.setState({
            error: false,
            errorMessage: ''
        });
    };
}

const styledChecker = withStyles(CheckerStyles)(Checker);

export default withTranslation()(styledChecker);