import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const RegulatorStyles = (theme: Theme) => createStyles({
    a: {
        strokeMiterlimit: 10,
        fill: 'none !important'
    },
});

interface RegulatorProps extends WithStyles<typeof RegulatorStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface RegulatorState {
    color: string,
    width: number,
    height: number,
}

class Regulator extends React.Component<RegulatorProps, RegulatorState> {
    constructor(props: RegulatorProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 30,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.003 17.485">
                <g transform="translate(-308.344 -606.454)">
                    <line className={classes.a} stroke={this.state.color} x2="7.888"
                          transform="translate(308.344 608.387)"/>
                    <line className={classes.a} stroke={this.state.color} x2="7.472"
                          transform="translate(320.875 608.387)"/>
                    <circle className={classes.a} stroke={this.state.color} cx="1.434" cy="1.434" r="1.434"
                            transform="translate(316.214 606.954)"/>
                    <g transform="translate(308.344 613.763)">
                        <line className={classes.a} stroke={this.state.color} x2="11.899"
                              transform="translate(0 1.434)"/>
                        <line className={classes.a} stroke={this.state.color} x2="3.461"
                              transform="translate(16.541 1.434)"/>
                        <circle className={classes.a} stroke={this.state.color} cx="1.434" cy="1.434" r="1.434"
                                transform="translate(11.88)"/>
                    </g>
                    <g transform="translate(308.344 620.571)">
                        <line className={classes.a} stroke={this.state.color} x2="4.027"
                              transform="translate(0 1.434)"/>
                        <line className={classes.a} stroke={this.state.color} x2="11.333"
                              transform="translate(8.67 1.434)"/>
                        <circle className={classes.a} stroke={this.state.color} cx="1.434" cy="1.434" r="1.434"
                                transform="translate(4.009)"/>
                    </g>
                </g>
            </svg>
        );
    }
}

const styledRegulator = withStyles(RegulatorStyles)(Regulator);

export default withTranslation()(styledRegulator);