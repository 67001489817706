import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const AlertStyles = (theme: Theme) => createStyles({
    a: {
        strokeWidth: 0.25
    }
});

interface AlertProps extends WithStyles<typeof AlertStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface AlertState {
    color: string,
    width: number,
    height: number,
}

class Alert extends React.Component<AlertProps, AlertState> {
    constructor(props: AlertProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 18,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.25 17.918">
                <g transform="translate(-217.512 -375.279)">
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M220.5,375.452a14.892,14.892,0,0,0,0,17.572l.455-.331a14.445,14.445,0,0,1,0-16.91Z"
                          transform="translate(0 0)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M356.989,375.944l-.412.3c3.537,5.608,3.535,11.181,0,16.78l.427.3A14.887,14.887,0,0,0,356.989,375.944Z"
                          transform="translate(-112.159 -0.397)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M247.089,391.408a9.61,9.61,0,0,0,.007,11.421l.455-.341a9.117,9.117,0,0,1,0-10.744Z"
                          transform="translate(-22.26 -12.88)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M334.472,391.853l-.45.326a9.214,9.214,0,0,1,0,10.6l.459.326a9.625,9.625,0,0,0,1.747-6.606A9.417,9.417,0,0,0,334.472,391.853Z"
                          transform="translate(-93.95 -13.24)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M276.731,397.759a4.04,4.04,0,0,1-.681-2.25v-2.445a4.041,4.041,0,0,0-3-3.9v-.744a.289.289,0,0,0-.289-.289h-1.5a.289.289,0,0,0-.289.289v.749a4.081,4.081,0,0,0-3,3.932v2.409a4.045,4.045,0,0,1-.681,2.251l-.859,1.289a.289.289,0,0,0,.241.45h3.372a2.135,2.135,0,0,0,3.943,0h3.358a.289.289,0,0,0,.241-.45Zm-4.71,2.477a1.561,1.561,0,0,1-1.319-.737h2.638A1.559,1.559,0,0,1,272.021,400.236Zm-4.8-1.316.56-.84a4.62,4.62,0,0,0,.779-2.572V393.1a3.5,3.5,0,0,1,2.767-3.421.289.289,0,0,0,.231-.283v-.688h.92v.685a.289.289,0,0,0,.232.283,3.461,3.461,0,0,1,2.767,3.389v2.445a4.618,4.618,0,0,0,.778,2.571l.56.84Z"
                          transform="translate(-39.355 -10.234)"/>
                </g>
            </svg>
        );
    }
}

const styledAlert = withStyles(AlertStyles)(Alert);

export default withTranslation()(styledAlert);