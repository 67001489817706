import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const CalculatorStyles = (theme: Theme) => createStyles({
    a: {
        fill: 'none !important',
        strokeLinejoin: 'round',
    },
    b: {
        fill: 'none !important',
        strokeLinejoin: 'round',
        strokeWidth: 0.75
    },
});

interface CalculatorProps extends WithStyles<typeof CalculatorStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface CalculatorState {
    color: string,
    width: number,
    height: number,
}

class Calculator extends React.Component<CalculatorProps, CalculatorState> {
    constructor(props: CalculatorProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 30,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.35 20.997">
                <g transform="translate(102.249 -447.933)">
                    <rect className={classes.a} stroke={this.state.color} width="14.35" height="14.35"
                          transform="translate(-101.749 454.08)"/>
                    <rect className={classes.a} stroke={this.state.color} width="14.35" height="5.642"
                          transform="translate(-101.749 448.433)"/>
                    <g transform="translate(-99.373 456.259)">
                        <line className={classes.b} stroke={this.state.color} x2="3.599"
                              transform="translate(6 1.799)"/>
                        <line className={classes.b} stroke={this.state.color} x2="3.599"
                              transform="translate(0 1.799)"/>
                        <line className={classes.b} stroke={this.state.color} y2="3.599" transform="translate(1.799)"/>
                        <g transform="translate(6.527 6.566)">
                            <line className={classes.b} stroke={this.state.color} x2="2.545" y2="2.545"/>
                            <line className={classes.b} stroke={this.state.color} x1="2.545" y2="2.545"/>
                        </g>
                        <g transform="translate(0.056 5.657)">
                            <line className={classes.b} stroke={this.state.color} x2="3.599"
                                  transform="translate(0 2.167)"/>
                            <circle fill={this.state.color} cx="0.543" cy="0.543" r="0.543"
                                    transform="translate(1.256)"/>
                            <circle fill={this.state.color} cx="0.543" cy="0.543" r="0.543"
                                    transform="translate(1.256 3.248)"/>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

const styledCalculator = withStyles(CalculatorStyles)(Calculator);

export default withTranslation()(styledCalculator);