import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Paper from '@mui/material/Paper';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

const SectionCardStyles = (theme: Theme) => createStyles({
    root: {
        transition: 'opacity 1s ease-in-out'
    },
    disabled: {
        opacity: 0.5
    }
});

interface SectionCardProps extends WithStyles<typeof SectionCardStyles>, WithTranslation {
    rootClass?: string,
    headerClass?: string,
    contentClass?: string,
    disabled?: boolean,
    avatar?: React.ReactNode,
    title?: React.ReactNode,
    children?: React.ReactNode,
}

interface SectionCardState {

}

class SectionCard extends React.Component<SectionCardProps, SectionCardState> {
    constructor(props: SectionCardProps) {
        super(props);
        this.state = {}
    }

    render() {
        const classes = this.props.classes;

        let disabled = false;
        if (this.props.disabled) {
            disabled = this.props.disabled;
        }

        let rootClass = classes.root;
        if (this.props.rootClass) {
            rootClass += ' ' + this.props.rootClass;
        }

        let headerClass = '';
        if (this.props.headerClass) {
            headerClass += ' ' + this.props.headerClass;
        }

        let contentClass = '';
        if (this.props.contentClass) {
            contentClass += ' ' + this.props.contentClass;
        }

        return (
            <Paper square={true}
                   className={rootClass + (disabled ? ' ' + classes.disabled : '')}>
                {(this.props.avatar || this.props.title) &&
                <CardHeader className={headerClass}
                            avatar={this.props.avatar}
                            title={this.props.title}
                />}
                <CardContent className={contentClass}>
                    {this.props.children}
                </CardContent>
            </Paper>
        );
    }
}

const styledSectionCard = withStyles(SectionCardStyles)(SectionCard);

export default withTranslation()(styledSectionCard);