import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteData} from "../../DataObjects";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SectionCard from '../Partials/SectionCard';

const FetchedPageStyles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(4) + ' auto'
    },
    button: {
        marginTop: theme.spacing(6)
    }
});

interface FetchedPageProps extends WithStyles<typeof FetchedPageStyles>, WithTranslation {
    id: keyof RouteData,
    setFetchPage: any
}

interface FetchedPageState {
    oldid: string,
    content: string
}

class FetchedPage extends React.Component<FetchedPageProps, FetchedPageState> {
    constructor(props: FetchedPageProps) {
        super(props);
        this.state = {
            oldid: '',
            content: ''
        }
    }

    componentDidMount(): void {
        this.getContent();
    }

    componentDidUpdate(): void {
        if (this.state.oldid !== this.props.id) {
            this.getContent();
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <SectionCard rootClass={classes.root}
                         title={<Typography variant="h2">{this.props.t(this.props.id)}</Typography>}>
                <Typography dangerouslySetInnerHTML={{__html: this.state.content}}/>
                <Button
                    className={classes.button}
                    variant="contained" color="primary"
                    onClick={this.props.setFetchPage.bind(this, '')}>
                    {this.props.t('back')}
                </Button>
            </SectionCard>
        );
    }

    getContent = () => {
        fetch('fetch.php?id=' + this.props.id + '&lang=' + this.props.i18n.language)
            .then(response => response.text())
            .then((response: any) => {
                let script = /<script(.*)<\/script>/gi.exec(response);
                if (script !== null) {
                    response.replace(script[0], "");
                    const scriptTag = document.createElement("script");
                    scriptTag.async = true;
                    scriptTag.type = "text/javascript";

                    let src = / [\w:-]?src[\s]*?=[\s]*?("[^"]+"|'[^']+'|\w+)/gi.exec(script[0]);
                    if (src !== null) {
                        scriptTag.src = src[1].replace(/"/gi, "");
                    }

                    let id = /[\w:-]?id[\s]*?=[\s]*?("[^"]+"|'[^']+'|\w+)/gi.exec(script[0]);
                    if (id !== null) {
                        scriptTag.id = id[1].replace(/"/gi, "");
                    }

                    window.document.body.appendChild(scriptTag);
                }

                this.setState({
                    oldid: this.props.id,
                    content: response
                });
            })
            .catch((error: any) => {
                this.setState({
                    oldid: this.props.id,
                    content: 'Fehler beim Laden'
                });
            });
    };
}

const styledFetchedPage = withStyles(FetchedPageStyles)(FetchedPage);

export default withTranslation()(styledFetchedPage);