import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';
import DownloadSVG from '../SVGs/Download';
import {AbstractSystemData} from "../../FormData/SystemData";

const DownloadLinkStyles = (theme: Theme) => createStyles({
    root: {
        position: 'relative',
        display: 'block',
        padding: theme.spacing(6) + ' ' + theme.spacing(10),
        color: '#FFF',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover, &:active': {
            color: '#FFF'
        },
        '& svg': {
            position: 'absolute',
            left: 0,
            bottom: '50%',
            transform: 'translateY(50%)'
        }
    }
});

interface DownloadLinkProps extends WithStyles<typeof DownloadLinkStyles>, WithTranslation {
    system: AbstractSystemData,
    className?: string
}

interface DownloadLinkState {

}

class DownloadLink extends React.Component<DownloadLinkProps, DownloadLinkState> {
    constructor(props: DownloadLinkProps) {
        super(props);
        this.state = {}
    }

    render() {
        const classes = this.props.classes;

        return (
            <a href={this.props.system.getDownloadFile(this.props.i18n.language)}
               target="_blank" rel="noopener noreferrer" download className={classes.root + (this.props.className ? ' ' + this.props.className : '')}>
                <DownloadSVG color="#FFF"/>
                {this.props.t('results.measures.download', {
                    serie: this.props.t('system.items.' + this.props.system.downloadSystem),
                    refrigerant: this.props.t('system.items.r32')
                })}
            </a>
        );
    }
}

const styledDownloadLink = withStyles(DownloadLinkStyles)(DownloadLink);

export default withTranslation()(styledDownloadLink);