export default class Multipliers {
    private _maxRefrigerantAmount: number;
    private _breakpointOneTwo: number;
    private _breakpointTwoThree: number;
    private _breakpointDeadzone: number;

    constructor(
        maxRefrigerantAmount: number = 52,
        breakpointOneTwo: number = 0.25,
        breakpointTwoThree: number = 0.5,
        breakpointDeadzone: number = 52
    ) {
        this._maxRefrigerantAmount = maxRefrigerantAmount;
        this._breakpointOneTwo = breakpointOneTwo;
        this._breakpointTwoThree = breakpointTwoThree;
        this._breakpointDeadzone = breakpointDeadzone;
    }

    get maxRefrigerantAmount(): number {
        return this._maxRefrigerantAmount;
    }

    set maxRefrigerantAmount(value: number) {
        this._maxRefrigerantAmount = value;
    }

    get breakpointOneTwo(): number {
        return this._breakpointOneTwo;
    }

    set breakpointOneTwo(value: number) {
        this._breakpointOneTwo = value;
    }

    get breakpointTwoThree(): number {
        return this._breakpointTwoThree;
    }

    set breakpointTwoThree(value: number) {
        this._breakpointTwoThree = value;
    }

    get breakpointDeadzone(): number {
        return this._breakpointDeadzone;
    }

    set breakpointDeadzone(value: number) {
        this._breakpointDeadzone = value;
    }
}