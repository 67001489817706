import {
    AbstractSystemData,
    ChillerSystemData,
    EcodanSystemData,
    HvrfSystemData,
    MrSlimSystemData,
    MSerieSystemData,
    PacIfSystemData,
    SMextSystemData,
    VrfSystemData
} from "../FormData/SystemData";

export default function getSystem(serie: string): AbstractSystemData {
   switch (serie) {
        case 'chiller':
            return new ChillerSystemData();
        case 'ecodan':
            return new EcodanSystemData();
        case 'hvrf':
            return new HvrfSystemData();
        case 'mrSlim':
            return new MrSlimSystemData();
        case 'mSerie':
            return new MSerieSystemData();
        case 'pacIf':
            return new PacIfSystemData();
        case 'sMext':
            return new SMextSystemData();
        case 'vrf':
            return new VrfSystemData();
        default:
            return new AbstractSystemData();
    }
}