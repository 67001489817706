import React from "react";
import {StepIconGenerator} from "../Helper";

import NormSVG from "../Components/SVGs/Norm";
import RegulatorSVG from "../Components/SVGs/Regulator";
import ToolsSVG from "../Components/SVGs/Tools";
import CalculatorSVG from "../Components/SVGs/Calculator";
import ConnectionSVG from "../Components/SVGs/Connection";

export const STEP_NORM = 0;
export const STEP_SYSTEM = 1;
export const STEP_LOCATION = 2;
export const STEP_CHECKER = 3;
export const STEP_RESULTS = 4;
export const STEP_CHECKOUT = 5;

export const STEPS: React.ElementType[] = [
    (props) => (
        <StepIconGenerator {...props}>
            <NormSVG width={20} height={20}/>
        </StepIconGenerator>
    ),
    (props) => (
        <StepIconGenerator {...props}>
            <RegulatorSVG width={20} height={17.5}/>
        </StepIconGenerator>
    ),
    (props) => (
        <StepIconGenerator {...props}>
            <ToolsSVG width={20.5} height={20.5}/>
        </StepIconGenerator>
    ),
    (props) => (
        <StepIconGenerator {...props}>
            <CalculatorSVG color="#b7bc23" width={15.3} height={21}/>
        </StepIconGenerator>
    ),
    (props) => (
        <StepIconGenerator {...props}>
            <ConnectionSVG width={29} height={21}/>
        </StepIconGenerator>
    )
];