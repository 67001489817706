import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';
import {ValidatorForm} from 'react-material-ui-form-validator';

import Project from "../Sections/Checkout/Project";

import de from "../../locales/de/translation.json";
import en from "../../locales/en/translation.json";
import nl from "../../locales/nl/translation.json";
import fr from "../../locales/fr/translation.json";
import {Norm2018, Norm2022} from "../../Norms";

const CheckoutStyles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(4) + ' auto'
    }
});

interface CheckoutProps extends WithStyles<typeof CheckoutStyles>, WithTranslation {
    norm: Norm2018|Norm2022,
    updateNorm: any,
    resetApp: any
}

interface CheckoutState {
    error: boolean | null,
    loading: boolean
}

class Checkout extends React.Component<CheckoutProps, CheckoutState> {
    constructor(props: CheckoutProps) {
        super(props);

        this.state = {
            error: null,
            loading: false
        };
    }

    render() {
        const classes = this.props.classes;

        return (
            // @ts-ignore
            <ValidatorForm
                className={classes.root}
                onSubmit={this.handleSumbit}>
                <Project error={this.state.error} loading={this.state.loading} norm={this.props.norm}
                         updateNorm={this.props.updateNorm.bind(this)} resetApp={this.props.resetApp.bind(this)}/>
            </ValidatorForm>
        );
    }

    handleSumbit = () => {
        this.setState({
            loading: true
        });

        let translations: any = de;
        switch (this.props.i18n.language) {
            case 'en':
                translations = en;
                break;
            case 'nl':
                translations = nl;
                break;
            case 'fr':
                translations = fr;
                break;
        }

        fetch('/pdf-mail', {
            method: 'POST',
            body: JSON.stringify({
                norm: this.props.norm.identifier,
                data: this.props.norm.getData(),
                formDefinition: this.props.norm.system.formDefinition,
                language: this.props.i18n.language,
                translations: translations
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.text();
            })
            .then((response) => {
                let blob = this.b64toBlob(response, 'application/pdf');
                let url = URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.href = url;
                link.download = this.props.norm.meta.name + '.pdf';
                link.dispatchEvent(new MouseEvent('click'));

                let thisCheckout = this;
                setTimeout(function () {
                    thisCheckout.setState({
                        error: false,
                        loading: false
                    });
                }, 1500);
            })
            .catch(() => {
                let thisCheckout = this;
                setTimeout(function () {
                    thisCheckout.setState({
                        loading: false,
                        error: true
                    });
                }, 1500);
            });
    };

    b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    };
}

const styledCheckout = withStyles(CheckoutStyles)(Checkout);

export default withTranslation()(styledCheckout);