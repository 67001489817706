export default class AmountUnit {
    private _amount: number;
    private _unit: string;

    constructor(
        amount: number = 0,
        unit: string = ''
    ) {
        this._amount = amount;
        this._unit = unit;
    }

    get amount(): number {
        return this._amount;
    }

    set amount(value: number) {
        this._amount = value;
    }

    get unit(): string {
        return this._unit;
    }

    set unit(value: string) {
        this._unit = value;
    }
}