export default class MinMaxValue {
    private readonly _min: number | undefined;
    private readonly _max: number | undefined;
    private _value: number;

    constructor(
        min: number | undefined = undefined,
        max: number | undefined = undefined,
        value: number = 0
    ) {
        this._min = min;
        this._max = max;
        this._value = value;
    }

    get min(): number | undefined {
        return this._min;
    }

    get max(): number | undefined {
        return this._max;
    }

    get value(): number {
        return this._value;
    }

    set value(value: number) {
        this._value = value;
    }

    get validValue(): number {
        if (this._min !== undefined && this._value <= this._min) {
            return this._min;
        } else if (this._max !== undefined && this._value >= this._max) {
            return this._max;
        } else {
            return this._value;
        }
    }
}