import AbstractSystemData from "./AbstractSystemData";
import {FieldDefinition, MinMaxValue} from "../../DataObjects";

export default class PacIfSystemData extends AbstractSystemData {
    private _smallestArea: MinMaxValue;
    private _airflowHeight: MinMaxValue;
    private _inBuilding: boolean;

    constructor(
        smallestArea: MinMaxValue = new MinMaxValue(0, 250),
        airflowHeight: MinMaxValue = new MinMaxValue(0, 2.2),
        inBuilding: boolean = true,
    ) {
        super();
        this._smallestArea = smallestArea;
        this._airflowHeight = airflowHeight;
        this._inBuilding = inBuilding;
    }

    get id(): string {
        return 'pacIf';
    }

    get smallestArea(): MinMaxValue {
        return this._smallestArea;
    }

    set smallestArea(value: MinMaxValue|number) {
        if (value instanceof MinMaxValue) {
            this._smallestArea = value;
        } else {
            this._smallestArea.value = value;
        }
    }

    get airflowHeight(): MinMaxValue {
        return this._airflowHeight;
    }

    set airflowHeight(value: MinMaxValue|number) {
        if (value instanceof MinMaxValue) {
            this._airflowHeight = value;
        } else {
            this._airflowHeight.value = value;
        }
    }

    get inBuilding(): boolean {
        return this._inBuilding;
    }

    set inBuilding(value: boolean) {
        this._inBuilding = value;
    }

    get formDefinition(): FieldDefinition[] {
        // @ts-ignore
        let formDefinition = super.formDefinition;

        for (let i = 0; i < formDefinition.length; i++) {
            if (formDefinition[i].id === 'numberOfUnits') {
                formDefinition.splice(i, 1);
            }
        }

        formDefinition.push({
                id: 'smallestArea',
                type: 'number',
                options: {
                    min: 0,
                    max: undefined,
                    unit: 'm²'
                }
            },
            {
                id: 'airflowHeight',
                type: 'number',
                options: {
                    min: 0,
                    max: 2.2,
                    unit: 'm'
                }
            },
            {
                id: 'inBuilding',
                type: 'checkbox'
            }
        );

        return formDefinition;
    }

    getData(): any {
        let data = super.getData();

        return {
            ...data,
            ...{
                smallestArea: this.smallestArea.value,
                airflowHeight: this.airflowHeight.value,
                inBuilding: this.inBuilding,
                valid: {
                    ...data.valid,
                    ...{
                        smallestArea: this.smallestArea.validValue,
                        airflowHeight: this.airflowHeight.validValue,
                    }
                }
            }
        }
    }

    setData(data: any = []) {
        super.setData(data);

        Object.keys(data).map((key: string) => {
            switch (key) {
                case 'smallestArea':
                    this.smallestArea = data[key];
                    break;
                case 'airflowHeight':
                    this.airflowHeight = data[key];
                    break;
                case 'inBuilding':
                    this.inBuilding = data[key];
                    break;
            }
            return key;
        });
    }

    /* * * * * * *
     * Templates *
     * * * * * * */

    getIllustrations(language: string = 'en'): any {
        let illustrations = super.getIllustrations(language);

        if (language !== 'de' && language !== 'en') {
            language = 'en';
        }

        illustrations['detector'] = require('../../images/' + language + '/detector.png');

        return illustrations;
    }
}