import AbstractSystemData from "./AbstractSystemData";
import {FieldDefinition} from "../../DataObjects";

export default class EcodanSystemData extends AbstractSystemData {
    get id(): string {
        return 'ecodan';
    }

    get illustrationSystem(){
        return 'mrSlim';
    }

    get downloadSystem(){
        return 'mrSlim';
    }

    get formDefinition(): FieldDefinition[] {
        // @ts-ignore
        let formDefinition = super.formDefinition;

        for(let i=0; i < formDefinition.length; i++) {
            if (formDefinition[i].id === 'numberOfUnits') {
                formDefinition.splice(i, 1);
            }
        }

        return formDefinition;
    }
}