import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import StepConnector from '@mui/material/StepConnector';

const StepperBarStyles = (theme: Theme) => createStyles({
    root: {
        position: 'relative',
        height: 45 + parseInt(theme.spacing(16)),
        overflow: 'hidden',
        '& .MuiStepper-root': {
            background: 'transparent',
            padding: theme.spacing(8) + ' 0',
            width: 'calc(125% - 75px)',
            position: 'absolute',
            right: '50%',
            transform: 'translateX(50%)'
        }
    }
});

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    active: {
        '& $line': {
            backgroundColor: '#fff',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: '#fff',
        },
    },
    line: {
        backgroundColor: '#464646',
        height: 3,
        width: '100%',
        margin: '0 auto',
        border: 0,
        transition: 'background-color 1s ease-in-out'
    },
})(StepConnector);

interface StepperBarProps extends WithStyles<typeof StepperBarStyles>, WithTranslation {
    steps: React.ElementType[],
    activeStep: number,
    handleStep: any
}

interface StepperBarState {

}

class StepperBar extends React.Component<StepperBarProps, StepperBarState> {
    constructor(props: StepperBarProps) {
        super(props);
        this.state = {}
    }

    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Stepper alternativeLabel activeStep={this.props.activeStep} connector={<ColorlibConnector/>}>
                    {this.props.steps.map((step, i) => (
                        <Step key={i}>
                            <StepButton onClick={this.handleStep.bind(this, i)}>
                                <StepLabel StepIconComponent={step}> </StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </div>
        );
    }

    handleStep = (step: number) => {
        this.props.handleStep(step);
    }
}

const styledStepperBar = withStyles(StepperBarStyles)(StepperBar);

export default withTranslation()(styledStepperBar);