import React from "react";

import AlertSVG from '../Components/SVGs/Alert';
import CirculationSVG from '../Components/SVGs/Circulation';
import DetectorSVG from '../Components/SVGs/Detector';
import ValveSVG from '../Components/SVGs/Valve';
import VentilationSVG from '../Components/SVGs/Ventilation';

export default function getIcon(id: string, color: string = "#464646") {
    switch(id){
        case 'alert':
            return <AlertSVG color={color}/>
        case 'circulation':
            return <CirculationSVG color={color}/>
        case 'detector':
            return <DetectorSVG color={color}/>
        case 'valve':
            return <ValveSVG color={color}/>
        case 'ventilation':
            return <VentilationSVG color={color}/>
    }
}