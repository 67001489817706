import AbstractSystemData from "./AbstractSystemData";
import {FieldDefinition} from "../../DataObjects";
import {Norm2022} from "../../Norms";

export default class MSerieSystemData extends AbstractSystemData {
    private _floorUnit: boolean;

    constructor(floorunit: boolean = false) {
        super();
        this._floorUnit = floorunit;
    }

    get id(): string {
        return 'mSerie';
    }

    get floorUnit(): boolean {
        return this._floorUnit;
    }

    set floorUnit(value: boolean) {
        this._floorUnit = value;
    }

    get formDefinition(): FieldDefinition[] {
        // @ts-ignore
        let formDefinition = super.formDefinition;

        formDefinition.push({
                id: 'floorUnit',
                type: 'checkbox'
            }
        );

        return formDefinition;
    }

    getData(): any {
        let data = super.getData();

        return {
            ...data,
            ...{
                floorUnit: this.floorUnit
            }
        }
    }

    setData(data: any = []) {
        super.setData(data);

        Object.keys(data).map((key: string) => {
            switch (key) {
                case 'floorUnit':
                    this.floorUnit = data[key];
                    if (this.normIdentifier === Norm2022.identifier && data[key]) {
                        this.circulation = data[key];
                    }
                    break;
                case 'circulation':
                    if (this.normIdentifier === Norm2022.identifier && this.floorUnit) {
                        this.circulation = this.floorUnit;
                    }
                    break;
            }
            return key;
        });
    }
}