import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SectionCard from '../Partials/SectionCard';
import WarningSVG from '../SVGs/Warning';

const HintStyles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(4) + ' auto'
    },
    button: {
        marginTop: theme.spacing(6)
    }
});

interface HintProps extends WithStyles<typeof HintStyles>, WithTranslation {
    acceptHint: any
}

interface HintState {

}

class Hint extends React.Component<HintProps, HintState> {
    render() {
        const classes = this.props.classes;

        return (
            <SectionCard rootClass={classes.root}
                         avatar={<WarningSVG/>}
                         title={<Typography variant="h2">{this.props.t('hint.headline')}</Typography>}>
                <Typography dangerouslySetInnerHTML={{__html: this.props.t('hint.bodytext')}}/>
                <Button
                    className={classes.button}
                    variant="contained" color="primary"
                    onClick={this.props.acceptHint.bind(this)}>
                    {this.props.t('hint.button')}
                </Button>
            </SectionCard>
        );
    }
}

const styledHint = withStyles(HintStyles)(Hint);

export default withTranslation()(styledHint);