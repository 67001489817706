import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';
import {TextValidator} from 'react-material-ui-form-validator';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from "@mui/material/Tooltip";
import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';

import {FieldDefinition} from "../../DataObjects";
import {getEmptyStringOr} from "../../Helper";
import {ClickAwayListener} from "@mui/material";
import {Norm2018, Norm2022} from "../../Norms";

const AdditionalInputAreaStyles = (theme: Theme) => createStyles({
    root: {},
    label: {
        pointerEvents: 'auto',
        position: 'relative',
        paddingRight: 20,
        color: theme.palette.text.primary,
    },
    labelicon: {
        width: 35,
        height: 15,
        paddingLeft: 20,
        position: 'absolute',
        right: 0,
        bottom: '50%',
        transform: 'translateY(50%)'
    },
    checkbox: {
        border: '1px solid ' + theme.palette.secondary.contrastText,
        width: 30,
        height: 30
    },
    checkedCheckbox: {
        border: '1px solid ' + theme.palette.secondary.contrastText,
        background: theme.palette.secondary.contrastText,
        width: 30,
        height: 30
    },
    tooltipTitle: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        '& img': {
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '50vh',
            justifySelf: 'center',
            alignSelf: 'center',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        }
    }
});

interface AdditionalInputAreaProps extends WithStyles<typeof AdditionalInputAreaStyles>, WithTranslation {
    norm: Norm2018|Norm2022,
    updateNorm: any,
    disabled: boolean
}

interface AdditionalInputAreaState {
    tooltipState: boolean[]
}

class AdditionalInputArea extends React.Component<AdditionalInputAreaProps, AdditionalInputAreaState> {
    constructor(props: AdditionalInputAreaProps) {
        super(props);

        this.state = {
            tooltipState: []
        }
    }

    handleSelectedChange = (key: string, event: any) => {
        const value = event.target.value;
        this.props.updateNorm({[key]: value});
    };

    handleNumberChange = (key: string, event: any) => {
        const value = event.currentTarget.value;

        this.props.updateNorm({[key]: value.replace(/[^0-9,.]/g, "").replace(",", ".")});
    };

    handleCheckedChange = (key: string, event: any) => {
        const value = event.currentTarget.checked;
        this.props.updateNorm({[key]: value});
    };

    render() {
        const classes = this.props.classes;
        let inputs = undefined;

        let formDefinition = this.props.norm.system.formDefinition;
        let index = 0;
        if (formDefinition && formDefinition.length > 0) {
            inputs = formDefinition.map((fieldDefinition: FieldDefinition) => {
                let label = this.props.t('system.general.' + fieldDefinition.id);
                let tooltip = this.getTooltip(fieldDefinition)
                if (tooltip instanceof React.Component || label !== tooltip) {
                    fieldDefinition = {
                        ...fieldDefinition,
                        ...{
                            options: {
                                ...fieldDefinition.options,
                                ...{
                                    tooltipIndex: index
                                }
                            }
                        }
                    };
                    index++;
                }

                switch (fieldDefinition.type) {
                    case 'number':
                        return this.getNumberField(fieldDefinition);
                    case 'checkbox':
                        return this.getCheckBox(fieldDefinition);
                    case 'select':
                        return this.getSelect(fieldDefinition);
                    default:
                        return this.getCheckBox(fieldDefinition);
                }
            });
        }

        return (
            <div className={classes.root}>
                {inputs}
            </div>
        );
    };

    getSelect = (fieldDefinition: FieldDefinition) => {
        switch (fieldDefinition.options.type) {
            case 'single':
                // @ts-ignore
                return <TextValidator
                    variant="standard"
                    key={fieldDefinition.id}
                    label={this.getLabel(fieldDefinition)}
                    name={fieldDefinition.id}
                    select
                    value={getEmptyStringOr(this.props.norm.system.getData()[fieldDefinition.id])}
                    onChange={this.handleSelectedChange.bind(this, fieldDefinition.id)}
                    disabled={this.props.disabled}
                >
                    {fieldDefinition.options.items.map((item: string, i: number) => (
                        <MenuItem key={i}
                                  value={item}>{this.props.t(this.getTranslationKey(fieldDefinition, i))}</MenuItem>
                    ))}
                </TextValidator>;

            case 'counter':
            default:
                let start = fieldDefinition.options.start || 1;
                let end = fieldDefinition.options.max;

                const items = [];
                for (let i = start; i <= end; i++) {
                    let label: string | number = i;

                    if (i === fieldDefinition.options.max && fieldDefinition.options.orMore) {
                        label = i + ' ' + this.props.t('system.or-more');
                    }

                    items.push(<MenuItem key={i} value={i}>
                        {label}
                    </MenuItem>);
                }

                // @ts-ignore
                return <TextValidator
                    variant="standard"
                    key={fieldDefinition.id}
                    label={this.getLabel(fieldDefinition)}
                    name={fieldDefinition.id}
                    select
                    value={getEmptyStringOr(this.props.norm.system.getData()[fieldDefinition.id])}
                    onChange={this.handleSelectedChange.bind(this, fieldDefinition.id)}
                    disabled={this.props.disabled}
                >
                    {items}
                </TextValidator>;
        }

    };

    getNumberField = (fieldDefinition: FieldDefinition) => {
        let validators = ['matchRegexp:^\\d+([\\.,]\\d{1,2})?$'];
        let errorMessages = [this.props.t('error.number')];

        if (fieldDefinition.options.min !== undefined) {
            validators.push('minFloat:' + fieldDefinition.options.min);
            errorMessages.push(this.props.t('error.min', {minimum: fieldDefinition.options.min}));
        }
        if (fieldDefinition.options.max !== undefined) {
            validators.push('maxFloat:' + fieldDefinition.options.max);
            errorMessages.push(this.props.t('error.max', {maximum: fieldDefinition.options.max}));
        }

        // @ts-ignore
        return <TextValidator
            variant="standard"
            key={fieldDefinition.id}
            label={this.getLabel(fieldDefinition)}
            name={fieldDefinition.id}
            type="text"
            InputProps={{
                endAdornment: <span dangerouslySetInnerHTML={{__html: fieldDefinition.options.unit || ''}}/>
            }}
            InputLabelProps={{
                shrink: true
            }}
            value={getEmptyStringOr(this.props.norm.system.getData()[fieldDefinition.id])}
            onChange={this.handleNumberChange.bind(this, fieldDefinition.id)}
            validators={validators}
            errorMessages={errorMessages}
            disabled={this.props.disabled}
        />;
    };

    getCheckBox = (fieldDefinition: FieldDefinition) => {
        const classes = this.props.classes;

        return <FormControlLabel
            key={fieldDefinition.id}
            label={this.getLabel(fieldDefinition)}
            control={<Checkbox icon={<div className={classes.checkbox}/>}
                               checkedIcon={
                                   <div className={classes.checkedCheckbox}>
                                       <Check color="secondary"/>
                                   </div>}
                               color="default"/>}
            labelPlacement="end"
            checked={this.props.norm.system.getData()[fieldDefinition.id]}
            onChange={this.handleCheckedChange.bind(this, fieldDefinition.id)}
            disabled={this.props.disabled}
        />;
    };

    getLabel(fieldDefinition: FieldDefinition) {
        const classes = this.props.classes;
        let label = this.props.t('system.general.' + fieldDefinition.id);

        let tooltip = this.getTooltip(fieldDefinition)
        if (tooltip instanceof React.Component || label !== tooltip) {
            let open = false;
            if(this.state.tooltipState[fieldDefinition.options.tooltipIndex]){
                open = true;
            }

            return <ClickAwayListener onClickAway={this.closeTooltip.bind(this, fieldDefinition.options.tooltipIndex)}>
                <div className={classes.label}>
                    <Tooltip
                        onClick={this.openTooltip.bind(this, fieldDefinition.options.tooltipIndex)}
                        onPointerEnter={this.openTooltip.bind(this, fieldDefinition.options.tooltipIndex)}
                        onPointerLeave={this.closeTooltip.bind(this, fieldDefinition.options.tooltipIndex)}
                        onClose={this.closeTooltip.bind(this, fieldDefinition.options.tooltipIndex)}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={this.getTooltip(fieldDefinition)} placement="top" arrow>
                        <div>
                            {label}
                            <InfoIcon className={classes.labelicon}/>
                        </div>
                    </Tooltip>
                </div>
            </ClickAwayListener>
        } else {
            return label;
        }
    };

    openTooltip(index: number) {
        this.setState(state => {
            let tooltipState = state.tooltipState;
            tooltipState[index] = true;

            return {
                tooltipState: tooltipState
            }
        });
    }

    closeTooltip(index: number) {
        this.setState(state => {
            let tooltipState = state.tooltipState;
            tooltipState[index] = false;

            return {
                tooltipState: tooltipState
            }
        });
    }

    getTooltip(fieldDefinition: FieldDefinition) {
        // Todo: change id to circulation
        if(fieldDefinition.id === 'circulation_missing') {
            const classes = this.props.classes;
            return <Box className={classes.tooltipTitle}>
                <img src={require('../../images/circulation.jpg')}
                     alt={this.props.t(`system.${this.props.norm.system.id}.circulation`)}/>
                <span>{this.props.t(this.getTranslationKey(fieldDefinition))}</span>
            </Box>;
        }
        return this.props.t(this.getTranslationKey(fieldDefinition));
    };

    getTranslationKey(fieldDefinition: FieldDefinition, itemIndex: number = -1): string {
        if (itemIndex >= 0) {
            return 'system.items.' + fieldDefinition.options.items[itemIndex];
        }

        return 'system.' + this.props.norm.system.id + '.' + fieldDefinition.id;
    };
}

const styledAdditionalInputArea = withStyles(AdditionalInputAreaStyles)(AdditionalInputArea);

export default withTranslation()(styledAdditionalInputArea);
