import React, {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import FetchedPage from "./Components/FooterLinks/FetchedPage";
import {RouteData} from './DataObjects';

import Box from '@mui/material/Box';

import {Norm2018, Norm2022} from "./Norms";
import {PacIfSystemData} from "./FormData/SystemData";
import {STEP_CHECKER, STEP_LOCATION, STEP_NORM, STEP_RESULTS, STEP_SYSTEM, STEP_CHECKOUT} from "./Configurations";

import Base from './Components/Layout/Base';
import Form from './Components/Views/Form';
import Result from "./Components/Views/Result";
import Checkout from "./Components/Views/Checkout";
import Loading from "./Components/Partials/Loading";
import Hint from "./Components/Views/Hint";
import Norm from "./Components/Views/Norm";
import {getSystem} from "./Helper";

interface AppProps {

}

interface AppState {
    isLoading: boolean,
    norm: Norm2018|Norm2022,
    diagram: string,
    activeStep: number,
    hintAccepted: boolean
    fetchPage: keyof RouteData | ''
}

class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            isLoading: false,
            norm: new Norm2018(),
            diagram: '',
            activeStep: 0,
            hintAccepted: false,
            fetchPage: ''
        }
    }

    render() {
        let loading = null;

        if (this.state.isLoading) {
            loading = <Loading/>
        }

        return (
            <Suspense fallback={loading}>
                <Base loading={loading}
                      activeStep={this.state.activeStep}
                      handleStep={this.handleStep.bind(this)}
                      setFetchPage={this.setFetchPage.bind(this)}>
                    {this.state.fetchPage !== '' &&
                        <FetchedPage id={this.state.fetchPage}
                                     setFetchPage={this.setFetchPage.bind(this)}/>
                    }

                    {this.state.fetchPage === '' &&
                        <Box>
                            {!this.state.hintAccepted &&
                                <Hint acceptHint={this.acceptHint.bind(this)}/>
                            }

                            {this.state.hintAccepted &&
                                <Routes>
                                    {this.state.activeStep === STEP_NORM &&
                                        <Route path="/" element={
                                            <Norm setNorm={this.setNorm.bind(this)}/>
                                        }/>}

                                    {(this.state.activeStep === STEP_SYSTEM || this.state.activeStep === STEP_LOCATION || this.state.activeStep === STEP_CHECKER) &&
                                        <Route path="/" element={
                                            <Form norm={this.state.norm}
                                                  updateNorm={this.updateNorm.bind(this)}
                                                  activeStep={this.state.activeStep}
                                                  handleNext={this.handleNext.bind(this)}/>
                                        }/>}

                                    {this.state.activeStep === STEP_RESULTS &&
                                        <Route path="/" element={
                                            <Result norm={this.state.norm}
                                                    updateNorm={this.updateNorm.bind(this)}
                                                    handleNext={this.handleNext.bind(this)}/>
                                        }/>}

                                    {this.state.activeStep === STEP_CHECKOUT &&
                                        <Route path="/" element={
                                            <Checkout norm={this.state.norm}
                                                      updateNorm={this.updateNorm.bind(this)}
                                                      resetApp={this.handleReset.bind(this)}/>
                                        }/>}
                                </Routes>}
                        </Box>}
                </Base>
            </Suspense>
        );
    }

    acceptHint = () => {
        this.setState({hintAccepted: true});
    };

    setFetchPage = (fetch: keyof RouteData) => {
        this.setState({fetchPage: fetch})
    };

    handleReset = () => {
        this.setState({
            activeStep: STEP_NORM,
            norm: new Norm2018(),
        });
    };

    handleNext = () => {
        this.setState((state) => {
            if (state.norm.system instanceof PacIfSystemData &&
                !state.norm.system.inBuilding &&
                state.activeStep === STEP_SYSTEM) {
                return {
                    activeStep: state.activeStep + 2
                }
            }

            return {
                activeStep: state.activeStep + 1
            }
        });
    };

    handleStep = (step: number) => {
        this.setState({
            activeStep: step
        });
    };

    setNorm = (norm: Norm2018|Norm2022) => {
        norm.setData(this.state.norm.getData());

        this.setState({
            norm: norm,
        });

        this.handleNext();
    }

    updateNorm = (data: any) => {
        const norm = this.state.norm;
        const currentSerie = this.state.norm.system.id;
        const serie = data.serie ? data.serie : this.state.norm.system.id;
        if(currentSerie !== serie) {
            const system = getSystem(serie);
            system.setData({
                ...this.state.norm.system.getData(),
                ...data
            });
            norm.system = system;
            norm.system.normIdentifier = norm.identifier;
        }
        norm.setData(data);

        this.setState({
            norm: norm,
        });
    };
}

export default App;
