import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const ConnectionStyles = (theme: Theme) => createStyles({
    a: {
        fill: 'none !important',
        strokeMiterlimit: 10,
        strokeWidth: 0.75
    }
});

interface ConnectionProps extends WithStyles<typeof ConnectionStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface ConnectionState {
    color: string,
    width: number,
    height: number,
}

class Connection extends React.Component<ConnectionProps, ConnectionState> {
    constructor(props: ConnectionProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 30,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.997 20.946">
                <g transform="translate(-14.789 -536.534)">
                    <line className={classes.a} stroke={this.state.color} x2="7.821" y2="4.52" transform="translate(25.19 544.63)"/>
                    <line className={classes.a} stroke={this.state.color} x1="4.916" y2="8.507" transform="translate(35.827 540.223)"/>
                    <circle className={classes.a} stroke={this.state.color} cx="1.786" cy="1.786" r="1.786" transform="translate(33.146 548.474)"/>
                    <circle className={classes.a} stroke={this.state.color} cx="1.786" cy="1.786" r="1.786" transform="translate(39.839 536.909)"/>
                    <line className={classes.a} stroke={this.state.color} x1="4.916" y2="8.507" transform="translate(17.845 545.281)"/>
                    <circle className={classes.a} stroke={this.state.color} cx="1.786" cy="1.786" r="1.786" transform="translate(15.164 553.532)"/>
                    <circle className={classes.a} stroke={this.state.color} cx="1.786" cy="1.786" r="1.786" transform="translate(21.857 541.966)"/>
                </g>
            </svg>
        );
    }
}

const styledConnection = withStyles(ConnectionStyles)(Connection);

export default withTranslation()(styledConnection);