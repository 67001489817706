import AbstractSystemData from "./AbstractSystemData";
import {FieldDefinition} from "../../DataObjects";
import {getEmptyStringOr} from "../../Helper";

export default class SMextSystemData extends AbstractSystemData {
    private _combination: string;

    constructor(combination: string = 'combi006') {
        super();
        this._combination = combination;
    }

    get id(): string {
        return 'sMext';
    }

    get illustrationSystem(){
        return 'mrSlim';
    }

    get downloadSystem(){
        return 'sMext';
    }

    get combination(): string {
        return this._combination;
    }

    set combination(value: string) {
        this._combination = value;
    }

    get formDefinition(): FieldDefinition[] {
        return [
            {
                id: 'combination',
                type: 'select',
                options: {
                    type: 'single',
                    items: [
                        'combi006',
                        'combi009',
                        'combi013',
                        'combi022',
                        'combi038',
                        'combi044'
                    ]
                }
            },
        ];
    }

    getData(): any {
        let data = super.getData();

        return {
            ...data,
            ...{
                combination: this.combination
            }
        }
    }

    setData(data: any = []) {
        super.setData(data);

        Object.keys(data).map((key: string) => {
            switch (key) {
                case 'combination':
                    this.combination = data[key];
                    break;
            }
            return key;
        });
    }

    /* * * * * * *
     * Templates *
     * * * * * * */

    getDownloadFile(language: string = 'en'): string {
        if(language !== 'de') {
            language = 'de';
        }

        let system = this.camelToSnake(this.downloadSystem);

        return require('../../files/safety_measures-' + system + '-r32-' + language + '.pdf');
    }

    /* * * * * *
     * Helpers *
     * * * * * */

    isReady(): boolean {
        return getEmptyStringOr(this.combination) !== '';
    }
}