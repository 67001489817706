import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const CirculationStyles = (theme: Theme) => createStyles({
    a: {
        strokeWidth: 0.45
    }
});

interface CirculationProps extends WithStyles<typeof CirculationStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface CirculationState {
    color: string,
    width: number,
    height: number,
}

class Circulation extends React.Component<CirculationProps, CirculationState> {
    constructor(props: CirculationProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 18,
            height: this.props.height ? this.props.height : 18,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.441 25.015">
                <g transform="translate(-218.204 -346.113)">
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M242.863,425.983l-1.337.631-1.315.62.221.455,1.852-.872a11.712,11.712,0,0,1-11.514,7.313,11.305,11.305,0,0,1-7-2.805,11.679,11.679,0,0,1-4.139-8.924h-.472a12.191,12.191,0,0,0,8.239,11.566,11.857,11.857,0,0,0,9.011-.4,12.165,12.165,0,0,0,6.328-6.493l.706,1.827.47-.186Z"
                          transform="translate(-0.563 -63.771)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M222.16,353.56l-.207-.461-1.863.822a11.681,11.681,0,0,1,11.212-7.049,11.3,11.3,0,0,1,7.037,2.7,11.679,11.679,0,0,1,4.275,9.046h.465a12.175,12.175,0,0,0-7.888-11.449,11.885,11.885,0,0,0-9.052.142,12.163,12.163,0,0,0-6.512,6.347l-.663-1.842-.474.172.988,2.759Z"
                          transform="translate(0)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M257.479,444.659l.039-.038c.055-.054.109-.108.162-.161.114-.115.221-.223.337-.322a1.867,1.867,0,0,1,1.623-.454,4.862,4.862,0,0,1,1.422.565c.2.111.408.225.612.339.395.221.8.449,1.214.654a3.154,3.154,0,0,0,1.419.372,2.509,2.509,0,0,0,.458-.042,2.864,2.864,0,0,0,1.72-1.132l.038-.047-.36-.3-.039.033-.135.115c-.093.078-.181.152-.264.231a1.9,1.9,0,0,1-2.03.5,8.455,8.455,0,0,1-1.191-.521c-.272-.14-.543-.293-.8-.442s-.54-.306-.817-.448a3.113,3.113,0,0,0-2.006-.412,2.861,2.861,0,0,0-1.757,1.139l-.039.047Z"
                          transform="translate(-32.357 -81.139)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M257.656,389.4l.08-.09a2.04,2.04,0,0,1,2.569-.63,11.534,11.534,0,0,1,1.2.6l.153.084c.119.065.238.132.357.2a8.919,8.919,0,0,0,1.419.691,2.944,2.944,0,0,0,.972.175,2.7,2.7,0,0,0,2.157-1.177l.036-.047-.4-.325-.174.191a2.041,2.041,0,0,1-2.618.6,12.18,12.18,0,0,1-1.183-.6l-.194-.108-.282-.157a7.784,7.784,0,0,0-1.582-.738,2.6,2.6,0,0,0-2.951,1.059l-.035.046.391.323Z"
                          transform="translate(-32.441 -34.877)"/>
                    <path className={classes.a} fill={this.state.color} stroke={this.state.color}
                          d="M294.009,420.232a2.821,2.821,0,0,0,.777.114,2.654,2.654,0,0,0,2.139-1.185l.035-.047-.4-.322-.1.118c-.042.048-.083.093-.124.137a2.087,2.087,0,0,1-1.522.765,2.623,2.623,0,0,1-1.264-.324c-.4-.2-.8-.426-1.187-.641l-.351-.195a5.79,5.79,0,0,0-1.969-.78,2.629,2.629,0,0,0-2.463,1.164l-.033.046.389.321.092-.1.1-.108a2.01,2.01,0,0,1,2.488-.623,11.627,11.627,0,0,1,1.258.625l.182.1c.117.064.233.129.349.194A7.63,7.63,0,0,0,294.009,420.232Z"
                          transform="translate(-57.902 -59.956)"/>
                </g>
            </svg>
        );
    }
}

const styledCirculation = withStyles(CirculationStyles)(Circulation);

export default withTranslation()(styledCirculation);