import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {STEPS} from "../../Configurations";
import Stepper from '../Partials/StepperBar';

import {RouteData} from '../../DataObjects';

const BaseStyles = (theme: Theme) => createStyles({
    root: {
        'overflow-wrap': 'break-word',
        'word-wrap': 'break-word',
        '-webkit-hyphens': 'auto',
        '-ms-hyphens': 'auto',
        '-moz-hyphens': 'auto',
        'hyphens': 'auto'
    },
    header: {
        width: '100%',
        height: 180,
        background: theme.palette.background.default,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1
    },
    content: {
        ...theme.contentContainer,
        position: 'relative',
        height: '100%'
    },
    logo: {
        position: 'absolute',
        bottom: '50%',
        left: 0,
        transform: 'translateY(50%)'
    },
    languages: {
        position: 'absolute',
        bottom: '50%',
        right: 0,
        transform: 'translateY(50%)'
    },
    language: {
        fontSize: 20,
        fontWeight: 300,
        color: '#464646',
        minWidth: 0,
        padding: '0 ' + theme.spacing(1) + 'px',
        '&.active': {
            color: '#000'
        }
    },
    divider: {
        fontSize: 32,
        fontWeight: 500,
        color: theme.palette.primary.main,
        lineHeight: '52px',
        verticalAlign: 'middle'
    },
    main: {
        background: '#222',
        minHeight: 'calc(100vh - 180px)',
        marginTop: 180,
        position: 'relative'
    },
    background: {
        position: 'fixed',
        bottom: '0',
        right: '50%',
        transform: 'translateX(50%)',
        minWidth: '100%',
        minHeight: '100%',
        width: 'auto',
        height: 'auto',
        zIndex: 0
    },
    headline: {
        color: '#fff',
        hyphens: 'none'
    },
    intro: {
        margin: theme.spacing(8) + ' 0 ' + theme.spacing(10),
        color: '#fff'
    },
    mainContent: {
        ...theme.contentContainer,
        position: 'relative',
        width: '100%',
        height: '100%',
        paddingBottom: theme.spacing(10)
    },
    '@media screen and (min-width: 800px)': {
        language: {
            fontSize: 30
        }
    },
    version: {
        position: 'absolute',
        bottom: 5,
        left: 5,
        fontSize: 10,
        transition: 'font-size .5s ease-in-out',
        '&:hover': {
            fontSize: 20
        }
    },
    footer: {
        position: 'absolute',
        bottom: 15,
        right: 0
    },
    footerlink: {
        color: theme.palette.primary.contrastText,
        fontSize: 12,
        fontWeight: 400,
        margin: '0 15px',
        textDecoration: 'none',
        textTransform: 'none',
    },
    '@media screen and (max-width: 799px)': {
        footer: {
            position: 'relative',
        },
        footerlink: {
            display: 'block',
            margin: '0 auto',
            textAlign: 'center'
        },
    },
});

interface BaseProps extends WithStyles<typeof BaseStyles>, WithTranslation {
    loading: any,
    activeStep: number,
    handleStep: any,
    setFetchPage: any,
    children?: React.ReactNode,
}

interface BaseState {
    routes: RouteData,
    version: any
}

class Base extends React.Component<BaseProps, BaseState> {
    constructor(props: BaseProps) {
        super(props);
        this.state = {
            routes: new RouteData(),
            version: ''
        }
    }

    componentDidMount(): void {
        let language = this.props.i18n.language;
        switch(language) {
            case 'de':
            case 'en':
            case 'nl':
            case 'fr':
                document.documentElement.lang = language;
                break;
            default:
                this.changeLanguage('en');
                break;
        }

        this.getVersion();
    }

    componentDidUpdate(): void {
        document.documentElement.lang = this.props.i18n.language;
    }

    render() {
        const classes = this.props.classes;

        let languages = ['de', 'en', 'nl', 'fr'];

        return (
            <div className={classes.root}>
                <header className={classes.header}>
                    <Box className={classes.content}>
                        <img className={classes.logo}
                             src={require('../../images/logo.svg').default}
                             alt="Mitsubishi Electric Logo"/>
                        <Box className={classes.languages}>
                            {languages.map<React.ReactNode>((language) => {
                                let buttonClass = classes.language + (this.props.i18n.language === language ? ' active' : '');
                                let button = (
                                    <Button key={language}
                                            className={buttonClass}
                                            onClick={() => this.changeLanguage(language)}>
                                        {language}
                                    </Button>);
                                return button;
                            }).reduce((prev: any, curr: any, i: any) => {
                                let divider = <span key={'divider-' + i} className={classes.divider}>|</span>;
                                return [prev, divider, curr];
                            })}
                        </Box>
                    </Box>
                </header>
                <main className={classes.main}>
                    <img className={classes.background}
                         src={this.getViewportWidth() < 800 ? require('../../images/background-small.png') : require('../../images/background.png')}
                         alt="Polygon Background"/>
                    <Box id="base-content" className={classes.content}>
                            <Stepper
                                steps={STEPS}
                                activeStep={this.props.activeStep}
                                handleStep={this.handleStep.bind(this)}/>
                            <Typography variant="h1" className={classes.headline}>{this.props.t('headline')}</Typography>
                            <Typography className={classes.intro}>{this.props.t('intro')}</Typography>
                    </Box>
                    <Box className={classes.mainContent}>
                        {this.props.children}
                    </Box>
                    <Box className={classes.footer}>
                        {this.getLink('imprint')}
                        <Button className={this.props.classes.footerlink}
                                href={this.props.t('contactLink')}
                                target="_blank" >
                            {this.props.t('contact')}
                        </Button>
                        {this.getLink('privacyPolicy')}
                        {this.getLink('cookiePolicy')}
                    </Box>
                    <Box className={classes.version}>{this.state.version}</Box>
                </main>
            </div>
        );
    }

    getLink = (id: keyof RouteData) => {
        return <Button className={this.props.classes.footerlink} onClick={() => this.props.setFetchPage(id)}>{this.props.t(id)}</Button>;
    };

    getVersion = () => {
        fetch('VERSION.txt')
            .then(response => response.text())
            .then((response: any) => {
                this.setState({
                    version: response
                });
            })
            .catch((error: any) => {
                this.setState({
                    version: ''
                });
            });
    };

    changeLanguage = (language: string) => {
        this.props.i18n.changeLanguage(language);
        this.props.setFetchPage('');
    };

    handleStep = (step: number) => {
        this.props.handleStep(step);
    };

    getViewportWidth = () => {
        if(document && document.documentElement){
            return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        } else {
            return 0;
        }
    }
}

const styledBase = withStyles(BaseStyles)(Base);

export default withTranslation()(styledBase);