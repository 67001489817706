import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const CloseStyles = (theme: Theme) => createStyles({
    a: {
        fill: 'none !important',
        strokeWidth: 10
    }
});

interface CloseProps extends WithStyles<typeof CloseStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface CloseState {
    color: string,
    width: number,
    height: number,
}

class Close extends React.Component<CloseProps, CloseState> {
    constructor(props: CloseProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 30,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187.071 187.071">
                <g transform="translate(-1556.505 -356.505)">
                    <line className={classes.a} stroke={this.state.color} x2="180" y2="180" transform="translate(1560.041 360.041)"/>
                    <line className={classes.a} stroke={this.state.color} x1="180" y2="180" transform="translate(1560.041 360.041)"/>
                </g>
            </svg>
        );
    }
}

const styledClose = withStyles(CloseStyles)(Close);

export default withTranslation()(styledClose);