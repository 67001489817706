import {FieldDefinition, MinMaxValue, RefrigerantData} from "../../DataObjects";
import {getEmptyStringOr} from "../../Helper";
import {Norm2018, Norm2022} from "../../Norms";

export default class AbstractSystemData {
    private _normIdentifier: string = '';
    protected _numberOfUnits: number;
    protected _installationHeight: MinMaxValue;
    protected _exitHeight: MinMaxValue;
    protected _refrigerant: RefrigerantData;
    protected _refrigerantAmount: MinMaxValue;
    protected _circulation: boolean;

    constructor(
        numberOfUnits: number = 1,
        installationHeight: MinMaxValue = new MinMaxValue(0.60, 2.20),
        exitHeight: MinMaxValue = new MinMaxValue(0.60, 2.20),
        refrigerant: string = 'r32',
        refrigerantAmount: MinMaxValue = new MinMaxValue(0, undefined),
        circulation: boolean = false,
    ) {
        this._numberOfUnits = numberOfUnits;
        this._installationHeight = installationHeight;
        this._exitHeight = exitHeight;
        this._refrigerant = this.findRefrigerant(refrigerant);
        this._refrigerantAmount = refrigerantAmount;
        this._circulation = circulation;
    }

    get id(): string {
        return 'abstract';
    }

    get illustrationSystem() {
        return this.id;
    }

    get downloadSystem() {
        return this.id;
    }

    get normIdentifier(): string {
        return this._normIdentifier;
    }

    set normIdentifier(value: string) {
        this._normIdentifier = value;
    }

    get numberOfUnits(): number {
        return this._numberOfUnits;
    }

    set numberOfUnits(value: number) {
        let numberOfUnits = 1;
        for (let i = 0; i < this.formDefinition.length; i++) {
            if (this.formDefinition[i].id === 'numberOfUnits') {
                if (value <= this.formDefinition[i].options.max) {
                    numberOfUnits = this.formDefinition[i].options.start || 1;

                    if (value >= numberOfUnits) {
                        numberOfUnits = value;
                    }
                }
            }
        }
        this._numberOfUnits = numberOfUnits;
    }

    get installationHeight(): MinMaxValue {
        return this._installationHeight;
    }

    set installationHeight(value: MinMaxValue|number) {
        if (value instanceof MinMaxValue) {
            this._installationHeight = value;
        } else {
            this._installationHeight.value = value;
        }
    }

    get exitHeight(): MinMaxValue {
        return this._exitHeight;
    }

    set exitHeight(value: MinMaxValue|number) {
        if (value instanceof MinMaxValue) {
            this._exitHeight = value;
        } else {
            this._exitHeight.value = value;
        }
    }

    get refrigerant(): RefrigerantData {
        return this._refrigerant;
    }

    set refrigerant(value: RefrigerantData|string) {
        if (value instanceof RefrigerantData) {
            this._refrigerant = value;
        } else {
            this._refrigerant = this.findRefrigerant(value);
        }
    }

    private findRefrigerant(id: string): RefrigerantData {
        let refrigerants = [];
        switch(this.normIdentifier) {
            case Norm2022.identifier:
                refrigerants = Norm2022.refrigerants;
                break;
            default:
                refrigerants = Norm2018.refrigerants;
                break;
        }

        return refrigerants.filter(refrigerant => {
            return refrigerant.id === id
        })[0];
    }

    get refrigerantAmount(): MinMaxValue {
        return this._refrigerantAmount;
    }

    set refrigerantAmount(value: MinMaxValue|number) {
        if (value instanceof MinMaxValue) {
            this._refrigerantAmount = value;
        } else {
            this._refrigerantAmount.value = value;
        }
    }

    get circulation(): boolean {
        return this._circulation;
    }

    set circulation(value: boolean) {
        this._circulation = value;
    }

    get formDefinition(): FieldDefinition[] {
        switch (this.normIdentifier) {
            case Norm2018.identifier:
                return [
                    {
                        id: 'numberOfUnits',
                        type: 'select',
                        options: {
                            type: 'counter',
                            max: 4,
                            orMore: true
                        }
                    },
                    {
                        id: 'installationHeight',
                        type: 'number',
                        options: {
                            min: this._installationHeight.min,
                            max: this._installationHeight.max,
                            unit: 'm'
                        }
                    },
                    {
                        id: 'refrigerant',
                        type: 'select',
                        options: {
                            type: 'single',
                            items: Norm2018.refrigerants.map(refrigerant => refrigerant.id),
                        }
                    },
                    {
                        id: 'refrigerantAmount',
                        type: 'number',
                        options: {
                            min: this._refrigerantAmount.min,
                            max: this._refrigerantAmount.max,
                            unit: 'kg'
                        }
                    },
                ];
            case Norm2022.identifier:
                return [
                    {
                        id: 'exitHeight',
                        type: 'number',
                        options: {
                            min: this._exitHeight.min,
                            max: this._exitHeight.max,
                            unit: 'm'
                        }
                    },
                    {
                        id: 'refrigerant',
                        type: 'select',
                        options: {
                            type: 'single',
                            items: Norm2022.refrigerants.map(refrigerant => refrigerant.id),
                        }
                    },
                    {
                        id: 'refrigerantAmount',
                        type: 'number',
                        options: {
                            min: this._refrigerantAmount.min,
                            max: this._refrigerantAmount.max,
                            unit: 'kg'
                        }
                    },
                    {
                        id: 'circulation',
                        type: 'checkbox'
                    },
                ];
            default:
                return [];
        }
    }

    getData(): any {
        return {
            serie: this.id,
            numberOfUnits: this.numberOfUnits,
            installationHeight: this.installationHeight.value,
            exitHeight: this.exitHeight.value,
            refrigerant: this.refrigerant.id,
            refrigerantAmount: this.refrigerantAmount.value,
            circulation: this.circulation,

            valid: {
                installationHeight: this.installationHeight.validValue,
                exitHeight: this.exitHeight.validValue,
                refrigerantAmount: this.installationHeight.validValue
            },
        }
    }

    setData(data: any = {}) {
        Object.keys(data).map((key: string) => {
            switch (key) {
                case 'numberOfUnits':
                    this.numberOfUnits = data[key];
                    break;
                case 'installationHeight':
                    this.installationHeight = data[key];
                    break;
                case 'exitHeight':
                    this.exitHeight = data[key];
                    break;
                case 'refrigerant':
                    this.refrigerant = data[key];
                    break;
                case 'refrigerantAmount':
                    this.refrigerantAmount = data[key];
                    break;
                case 'circulation':
                    this.circulation = data[key];
                    break;
            }
            return key;
        });
    }

    /* * * * * * *
     * Templates *
     * * * * * * */

    getIllustrations(language: string = 'en'): any {
        if (language !== 'de' && language !== 'en') {
            language = 'en';
        }

        let system = this.camelToSnake(this.illustrationSystem);

        return {
            alert: require('../../images/' + language + '/alert-' + system + '.png'),
            valve: require('../../images/' + language + '/valve-' + system + '.png'),
            ventilation: require('../../images/' + language + '/ventilation-' + system + '.png'),
            circulation: require('../../images/circulation.jpg')
        }
    }

    getDownloadFile(language: string = 'en'): string {
        if (language !== 'de' && language !== 'en') {
            language = 'en';
        }

        let system = this.camelToSnake(this.downloadSystem);

        return require('../../files/safety_measures-' + system + '-r32-' + language + '.pdf');
    }

    /* * * * * *
     * Helpers *
     * * * * * */

    public camelToSnake(string: string) {
        return string.replace(/\.?([A-Z]+)/g, function (x, y) {
            return "_" + y.toLowerCase()
        }).replace(/^_/, "");
    }

    public isReady() {
        switch(this.normIdentifier) {
            case Norm2018.identifier:
                return getEmptyStringOr(this.installationHeight.value) !== '' &&
                    getEmptyStringOr(this.refrigerantAmount.value) !== '';
            case Norm2022.identifier:
                return getEmptyStringOr(this.exitHeight.value) !== '' &&
                    getEmptyStringOr(this.refrigerantAmount.value) !== '';
        }
    }
}