import AmountUnit from "./AmountUnit";

export default class RefrigerantData {
    private _id: string;
    private _lfl: AmountUnit;
    private _qlmv: AmountUnit;
    private _rcl: AmountUnit;
    private _qlav: AmountUnit;
    private _atel: AmountUnit;

    constructor(
        id: string = '',
        lfl: AmountUnit = new AmountUnit(),
        qlmv: AmountUnit = new AmountUnit(),
        rcl: AmountUnit = new AmountUnit(),
        qlav: AmountUnit = new AmountUnit(),
        atel: AmountUnit = new AmountUnit(),
    ) {
        this._id = id;
        this._lfl = lfl;
        this._qlmv = qlmv;
        this._rcl = rcl;
        this._qlav = qlav;
        this._atel = atel;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get lfl(): AmountUnit {
        return this._lfl;
    }

    set lfl(value: AmountUnit) {
        this._lfl = value;
    }

    get qlmv(): AmountUnit {
        return this._qlmv;
    }

    set qlmv(value: AmountUnit) {
        this._qlmv = value;
    }

    get rcl(): AmountUnit {
        return this._rcl;
    }

    set rcl(value: AmountUnit) {
        this._rcl = value;
    }

    get qlav(): AmountUnit {
        return this._qlav;
    }

    set qlav(value: AmountUnit) {
        this._qlav = value;
    }

    get atel(): AmountUnit {
        return this._atel;
    }

    set atel(value: AmountUnit) {
        this._atel = value;
    }
}