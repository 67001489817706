import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const DetectorStyles = (theme: Theme) => createStyles({
    a: {
        fill: 'none !important'
    },
    b: {
        strokeMiterlimit: 10
    },
    c: {
        fill: 'none !important',
        strokeMiterlimit: 10
    }
});

interface DetectorProps extends WithStyles<typeof DetectorStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface DetectorState {
    color: string,
    width: number,
    height: number,
}

class Detector extends React.Component<DetectorProps, DetectorState> {
    constructor(props: DetectorProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 18,
            height: this.props.height ? this.props.height : 18,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.183 20.156">
                <g transform="translate(-2438.59 -995.486)">
                    <g transform="translate(2452.511 999.026)">
                        <path className={classes.a} stroke={this.state.color} d="M3.267,0A3.267,3.267,0,1,1,0,3.267,3.267,3.267,0,0,1,3.267,0Z"
                              transform="translate(1.747 1.745)"/>
                        <line className={classes.b} stroke={this.state.color} x2="3.035" transform="translate(0 5.012)"/>
                        <line className={classes.b} stroke={this.state.color} x2="3.035" transform="translate(6.999 5.012)"/>
                        <line className={classes.b} stroke={this.state.color} y2="3.024" transform="translate(5.012 0)"/>
                        <line className={classes.b} stroke={this.state.color} y2="3.024" transform="translate(5.012 6.999)"/>
                    </g>
                    <g transform="translate(2439.25 995.986)">
                        <path className={classes.c} stroke={this.state.color}
                              d="M129.038,145.113s-8.252,9.578-18.431,9.578-18.431-9.578-18.431-9.578,8.252-9.578,18.431-9.578S129.038,145.113,129.038,145.113Z"
                              transform="translate(-92.175 -135.535)"/>
                        <path className={classes.c} stroke={this.state.color} d="M153.767,139.805a8.265,8.265,0,1,1-7.236-4.27"
                              transform="translate(-128.45 -135.535)"/>
                    </g>
                </g>
            </svg>
        );
    }
}

const styledDetector = withStyles(DetectorStyles)(Detector);

export default withTranslation()(styledDetector);