import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';
import {ValidatorForm} from 'react-material-ui-form-validator';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import {STEP_CHECKER, STEP_LOCATION, STEP_SYSTEM} from "../../Configurations";

import LocationForm from '../Sections/Form/Location';
import SystemForm from '../Sections/Form/System';
import Checker from '../Sections/Form/Checker';
import {Norm2018, Norm2022} from "../../Norms";
import {PacIfSystemData} from "../../FormData/SystemData";

const FormStyles = (theme: Theme) => createStyles({
    root: {
        ...theme.grid.oneColumn,
        margin: theme.spacing(4) + ' auto'
    },
    cardWrap: {
        display: 'grid',
        gridTemplateRows: '1fr ' + theme.spacing(12)
    },
    cardBottom: {
        width: '100%',
        height: '100%'
    },
    '@media (min-width: 1400px)': {
        root: {
            ...theme.grid.threeColumns,
        }
    }
});

interface FormProps extends WithStyles<typeof FormStyles>, WithTranslation {
    norm: Norm2018|Norm2022,
    updateNorm: any,
    activeStep: number,
    handleNext: any
}

interface FormState {
    loading: boolean
}

class Form extends React.Component<FormProps, FormState> {
    constructor(props: FormProps) {
        super(props);

        this.state = {
            loading: false
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            // @ts-ignore
            <ValidatorForm
                className={classes.root}
                onSubmit={() => {}}>
                <Box className={classes.cardWrap}>
                    <SystemForm norm={this.props.norm}
                                updateNorm={this.props.updateNorm.bind(this)}
                                disabled={this.state.loading}/>
                    {(this.props.norm.isSystemReady() && this.props.activeStep === STEP_SYSTEM) &&
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.cardBottom}
                        onClick={this.handleNext.bind(this)}>
                        {this.props.t('next')}
                    </Button>}
                </Box>
                <Box className={classes.cardWrap}>
                    <LocationForm norm={this.props.norm}
                                  updateNorm={this.props.updateNorm.bind(this)}
                                  disabled={this.props.activeStep < STEP_LOCATION || this.state.loading ||
                                  (this.props.norm.system instanceof PacIfSystemData && !this.props.norm.system.inBuilding)}/>
                    {(this.props.norm.isLocationReady() && this.props.activeStep === STEP_LOCATION) &&
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.cardBottom}
                        onClick={this.handleNext.bind(this)}>
                        {this.props.t('next')}
                    </Button>}
                </Box>
                <Box className={classes.cardWrap}>
                    <Checker checkInput={this.checkInput.bind(this)}
                             disabled={this.props.activeStep < STEP_CHECKER}
                             loading={this.state.loading}
                             setLoading={this.setLoading.bind(this)}/>
                </Box>
            </ValidatorForm>
        );
    }

    checkInput = () => {
        if (this.props.norm.isInputError()) {
            return {error: true, message: this.props.t('error.checker.' + this.props.norm.isInputError())};
        } else {
            this.handleNext();
        }
    };

    handleNext = () => {
        this.props.handleNext();
    };

    setLoading = (loading: boolean) => {
        this.setState({
            loading: loading
        })
    };
}

const styledForm = withStyles(FormStyles)(Form);

export default withTranslation()(styledForm);