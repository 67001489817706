export default class RouteData {
    private _imprint: string;
    private _privacyPolicy: string;
    private _cookiePolicy: string;

    constructor(
        imprint: string = '',
        privacyPolicy: string = '',
        cookiePolicy: string = ''
    ) {
        this._imprint = imprint;
        this._privacyPolicy = privacyPolicy;
        this._cookiePolicy = cookiePolicy;
    }

    get imprint(): string {
        return this._imprint;
    }

    set imprint(value: string) {
        this._imprint = value;
    }

    get privacyPolicy(): string {
        return this._privacyPolicy;
    }

    set privacyPolicy(value: string) {
        this._privacyPolicy = value;
    }

    get cookiePolicy(): string {
        return this._cookiePolicy;
    }

    set cookiePolicy(value: string) {
        this._cookiePolicy = value;
    }
}