import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import {getIcon} from "../../Helper";

import CloseSVG from '../SVGs/Close';
import {Norm2018, Norm2022} from "../../Norms";
import {PacIfSystemData} from "../../FormData/SystemData";

const MeasuresBoxStyles = (theme: Theme) => createStyles({
    root: {
        boxShadow: 'none',
        position: 'relative',
        zIndex: 1
    },
    background: {
        height: '100%',
        background: '#565656',
        color: theme.palette.primary.contrastText,
        position: 'relative',
        zIndex: 2
    },
    backgroundfilter: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0,0.75)',
        zIndex: 1
    },
    spaced: {
        ...theme.grid.oneColumn,
        margin: theme.spacing(2) + ' 0'
    },
    measureWrap: {
        margin: theme.spacing(2) + ' 0 ' + theme.spacing(6),
        '&:last-child': {
            margin: theme.spacing(2) + ' 0'
        },
        '&:first-child': {
            margin: '0 0 ' + theme.spacing(6)
        }
    },
    measure: {
        position: 'relative',
        backgroundColor: '#FFF',
        color: theme.palette.secondary.contrastText,
        height: theme.spacing(6),
        cursor: 'pointer',
        '& svg': {
            position: 'absolute',
            left: theme.spacing(5),
            bottom: '50%',
            transform: 'translate(-50%, 50%)',
            '&.right': {
                left: 'initial',
                right: '-' + theme.spacing(2)
            }
        },
        '& h4': {
            display: 'block',
            padding: 8,
            paddingLeft: theme.spacing(10)
        },
        '&.active': {
            background: '#000',
            color: '#FFF'
        }
    },
    measureContext: {
        position: 'fixed',
        bottom: '50%',
        right: '50%',
        transform: 'translate(50%, 50%)',
        width: '90vw',
        height: '90vh',
        overflowY: 'auto',
        background: '#000',
        color: '#fff',
        zIndex: 2,
        display: 'none',
        '&.active': {
            display: 'block'
        }
    },
    measureContextRight: {},
    showCase: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        background: '#FFF',
        '& img': {
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '50vh',
            justifySelf: 'center',
            alignSelf: 'center'
        }
    },
    description: {
        position: 'relative',
        padding: theme.spacing(6)
    },
    borderBottom: {
        position: 'absolute',
        bottom: 0,
        height: 1.5,
        width: 'calc(100% - ' + theme.spacing(12) + ')',
        background: '#FFF'
    },
    information: {
        padding: theme.spacing(6)
    },
    closeWrap: {
        position: 'absolute',
        right: theme.spacing(6),
        top: theme.spacing(6),
        cursor: 'pointer',
        '& svg': {
            filter: 'drop-shadow(0 0 1px rgba(0,0,0,0.75))'
        }
    },
    link: {
        position: 'relative',
        display: 'block',
        padding: theme.spacing(6) + ' ' + theme.spacing(10),
        color: '#FFF',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover, &:active': {
            color: '#FFF'
        },
        '& svg': {
            position: 'absolute',
            left: 0,
            bottom: '50%',
            transform: 'translateY(50%)'
        }
    },
    table: {
        marginBottom: theme.spacing(2),
        '& .MuiTableCell-root': {
            color: theme.palette.primary.contrastText,
            '&:nth-child(3)': {
                borderLeft: '5px solid ' + theme.palette.deadZone.main
            }
        },
        '& .MuiTableCell-sizeSmall': {
            padding: '5px 5px 5px 10px'
        },
        '& .MuiTableRow-root': {
            color: theme.palette.primary.contrastText,
            borderBottom: '5px solid ' + theme.palette.deadZone.main
        },
        '& .MuiTableCell-head': {
            fontSize: '1rem',
            fontWeight: 600,
            marginBottom: theme.spacing(2),
            verticalAlign: 'bottom'
        }
    },
    green: {
        background: theme.palette.success.main
    },
    red: {
        background: theme.palette.error.main
    },
    '@media screen and (min-width: 800px)': {
        description: {
            height: theme.spacing(60),
            overflowY: 'auto',
        },
        information: {
            position: 'relative',
            paddingRight: theme.spacing(24)
        }
    },
    '@media screen and (min-width: 1400px)': {
        measureContextRight: {
            position: 'absolute',
            bottom: 'initial',
            right: 'initial',
            transform: 'initial',
            left: '100%',
            top: '-' + theme.spacing(60),
            height: 'calc(100% + 100px)',
            overflowY: 'auto',
            background: '#000',
            color: '#fff',
            zIndex: 1,
            display: 'none',
            '&.active': {
                display: 'block'
            }
        },
    }
});

interface MeasuresBoxProps extends WithStyles<typeof MeasuresBoxStyles>, WithTranslation {
    className?: string,
    norm: Norm2018|Norm2022
}

interface MeasuresBoxState {
    activeMeasure: string | null;
}

class MeasuresBox extends React.Component<MeasuresBoxProps, MeasuresBoxState> {
    constructor(props: MeasuresBoxProps) {
        super(props);

        this.state = {
            activeMeasure: null
        }
    }

    render() {
        const classes = this.props.classes;

        if (document.documentElement) {
            if (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) >= 1400) {
                this.setMeasureContextWidth();
                window.onresize = () => {
                    this.setMeasureContextWidth();
                };
            } else {
                let measureContext = document.getElementById('measure-context');
                if (measureContext) {
                    measureContext.style.width = '90vw';
                }
            }
        }

        let headlineKey = "measures";
        if(this.props.norm.system instanceof PacIfSystemData && this.props.norm.getData().necessaryMeasures.length === 1) {
            headlineKey = "hint";
        }

        return (
            <Paper square={true} className={classes.root + ' ' + this.props.className}>
                {this.state.activeMeasure &&
                <Box className={classes.backgroundfilter}/>}
                <CardContent className={classes.background}>
                    {(this.props.norm.isToxicityHandlingNecessary() ||
                        (this.props.norm.system instanceof PacIfSystemData &&
                        !this.props.norm.system.inBuilding &&
                        this.props.norm.isSmallestToxic())
                    ) &&
                        this.getToxicityTable()
                    }
                    <Typography variant="h3">{this.props.t('results.' + headlineKey + '.headline')}</Typography>
                    {this.getMeasures()}
                </CardContent>
                <Box id="measure-context" className={classes.measureContext + ' ' + (this.props.norm.showDiagram() ? classes.measureContextRight : '')}>
                    <Box className={classes.showCase}>
                        {this.state.activeMeasure !== null &&
                        <img
                            src={this.props.norm.system.getIllustrations(this.props.i18n.language)[this.state.activeMeasure]}
                            alt={this.props.t('results.measures.alt', {measure: this.props.t('results.measures.items.' + this.state.activeMeasure)})}/>}
                    </Box>
                    <Box className={classes.information}>
                        <Box className={classes.closeWrap} onClick={this.deactivateAllMeasures.bind(this)}>
                            <CloseSVG color="#FFF"/>
                        </Box>
                        <Typography variant="h3">
                            {this.props.t('results.measures.items.' + this.state.activeMeasure)} - {this.props.t('results.measures.moreInformation')}
                        </Typography>
                        <Typography variant="body2"
                                    dangerouslySetInnerHTML={{__html: this.props.t('results.measures.description', {measure: this.props.t('results.measures.items.' + this.state.activeMeasure)})}}/>
                    </Box>
                </Box>
            </Paper>
        );
    }

    getMeasures() {
        const classes = this.props.classes;

        let standardSuffix = '';
        return this.props.norm.getData().necessaryMeasures.map((data: any) => {
            let intro = '';

            if(data.name === "alert") {
                standardSuffix = 'WithAlert';
            }

            switch (data.name) {
                case 'standard':
                    const numberOfMeasures = this.props.norm instanceof Norm2022 ? 0 : data.necessary;
                    intro = this.props.t('results.measures.intro.standard' + standardSuffix, {numberOfMeasures: this.props.t('results.measures.number.' + numberOfMeasures)});
                    break;
                default:
                    intro = this.props.t('results.measures.intro.' + data.name);
                    break;
            }

            let items = data.items.map((item: string) => {
                return this.getMeasureItem(item);
            })

            return (
                <Box key={data.name} className={classes.measureWrap}>
                    <Typography>
                        {intro}
                    </Typography>
                    <Box className={classes.spaced}>
                        {items}
                    </Box>
                </Box>
            );
        });
    }

    getMeasureItem(id: string) {
        const classes = this.props.classes;

        return (
            <Box id={id} key={id} component="div"
                 className={classes.measure + ' measure'}
                 onClick={this.activateMeasure.bind(this)}>
                {getIcon(id)}
                <Typography variant="h4">
                    {this.props.t('results.measures.items.' + id)}
                </Typography>
                <ChevronRightIcon className="right"/>
            </Box>
        );
    }

    setMeasureContextWidth = () => {
        let baseContent = document.getElementById('base-content');
        let measureContext = document.getElementById('measure-context');
        if (baseContent && measureContext) {
            let contentWidth = baseContent.offsetWidth;
            let padding = 30;
            let multiplier = 5 / 11;
            let gridGap = 30;

            measureContext.style.width = ((contentWidth - padding * 2) * multiplier - (gridGap / 2)) + gridGap + 'px';
        }
    };

    activateMeasure = (e: any) => {
        this.deactivateAllMeasures();

        let measureContext = document.getElementById('measure-context');
        if (measureContext) {
            e.currentTarget.classList.add('active');
            measureContext.classList.add('active');
        }

        this.setState({
            activeMeasure: e.currentTarget.id
        });
    };

    deactivateAllMeasures = () => {
        let measures = document.getElementsByClassName('measure');
        for (let i = 0; i < measures.length; i++) {
            if (measures[i].classList.contains('active')) {
                measures[i].classList.remove('active');
            }
        }

        let measureContext = document.getElementById('measure-context');
        if (measureContext && measureContext.classList.contains('active')) {
            measureContext.classList.remove('active');
        }

        this.setState({
            activeMeasure: null
        });
    };

    getToxicityTable(){
        const classes = this.props.classes;
        let translations: any = {
            category: {
                name: this.props.t('results.toxicity.category.name'),
                general: this.props.t('results.toxicity.category.general'),
                monitored: this.props.t('results.toxicity.category.monitored'),
                authorized: this.props.t('results.toxicity.category.authorized')
            },
            limit: {
                name: this.props.t('results.toxicity.limit.name'),
                necessary: this.props.t('results.toxicity.limit.necessary' + ((this.props.norm.system instanceof PacIfSystemData && !this.props.norm.system.inBuilding) ? 'Outside' : '')),
                notNecessary: this.props.t('results.toxicity.limit.notNecessary' + ((this.props.norm.system instanceof PacIfSystemData && !this.props.norm.system.inBuilding) ? 'Outside' : ''))
            }
        };

        return <Box>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>{translations.category.name}</TableCell>
                        <TableCell>{translations.limitName}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.red}>A</TableCell>
                        <TableCell
                            className={classes.red}>{translations.category.general}</TableCell>
                        <TableCell
                            className={classes.red}>{translations.limit.necessary}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.green}>B</TableCell>
                        <TableCell
                            className={classes.green}>{translations.category.monitored}</TableCell>
                        <TableCell className={classes.green}
                                   rowSpan={2}>{translations.limit.notNecessary}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.green}>C</TableCell>
                        <TableCell
                            className={classes.green}>{translations.category.authorized}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>;
    }
}

const styledMeasuresBox = withStyles(MeasuresBoxStyles)(MeasuresBox);

export default withTranslation()(styledMeasuresBox);