import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const SendStyles = (theme: Theme) => createStyles({
    a: {
        strokeWidth: 3,
        strokeLinejoin: 'round',
        fill: 'none !important'
    },
    b: {
        strokeWidth: 0.25
    }
});

interface SendProps extends WithStyles<typeof SendStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface SendState {
    color: string,
    width: number,
    height: number,
}

class Send extends React.Component<SendProps, SendState> {
    constructor(props: SendProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 266,
            height: this.props.height ? this.props.height : 266,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 75 276.492 191.607">
                <g transform="translate(-1269.279 -590.951)">
                    <g transform="translate(1399.488 593) rotate(30)">
                        <path className={classes.a} stroke={this.state.color} d="M149.007,32.505,87.042,173.327h58.991L166.547,0,0,173.327H58.991Z"
                              transform="translate(0 0)"/>
                        <path className={classes.a} stroke={this.state.color} d="M0,0,14.025,29.369,28.051,0" transform="translate(58.991 173.327)"/>
                    </g>
                    <g transform="translate(1269.506 784.272)">
                        <path className={classes.b} fill={this.state.color} stroke={this.state.color}
                              d="M2.342,9.107l.228-.223c.327-.32.642-.638.952-.95.67-.675,1.3-1.313,1.984-1.9a11,11,0,0,1,9.565-2.675A28.649,28.649,0,0,1,23.453,6.69c1.207.656,2.407,1.325,3.606,2,2.327,1.3,4.732,2.644,7.156,3.856a18.586,18.586,0,0,0,8.363,2.189,14.757,14.757,0,0,0,2.7-.248c3.787-.7,6.913-2.761,10.135-6.673l.226-.274L53.518,5.781l-.229.2c-.272.234-.537.457-.795.675-.549.46-1.065.894-1.556,1.363-3.557,3.4-7.582,4.388-11.961,2.937a49.875,49.875,0,0,1-7.02-3.073c-1.6-.822-3.2-1.727-4.742-2.6-1.565-.887-3.184-1.8-4.814-2.64C17.9.337,14.258-.414,10.582.211,6.726.866,3.533,2.935.229,6.924L0,7.2Z"
                              transform="translate(56.561 59.273) rotate(-30)"/>
                        <path className={classes.b} fill={this.state.color} stroke={this.state.color}
                              d="M2.8,8.59c.167-.185.322-.356.474-.528,4.423-5.021,9.092-6.166,15.14-3.71a67.923,67.923,0,0,1,7.065,3.536l.9.5c.7.386,1.4.78,2.1,1.173a52.535,52.535,0,0,0,8.359,4.075,17.348,17.348,0,0,0,5.726,1.029c4.819,0,9.144-2.341,12.711-6.938l.214-.275L53.152,5.534,52.125,6.662C47.439,11.785,42.969,12.8,36.7,10.17a71.738,71.738,0,0,1-6.971-3.547L28.58,5.988c-.556-.306-1.108-.617-1.662-.927A45.9,45.9,0,0,0,17.6.709C10.9-1.277,4.725.94.2,6.952L0,7.224,2.307,9.13Z"
                              transform="translate(11.05 27.745) rotate(-30)"/>
                        <path className={classes.b} fill={this.state.color} stroke={this.state.color}
                              d="M38.069,14.044a16.621,16.621,0,0,0,4.578.674c4.812,0,9.11-2.364,12.607-6.983l.208-.275-2.329-1.9-.618.693c-.25.283-.487.55-.731.807-2.889,3.04-5.738,4.471-8.967,4.507a15.468,15.468,0,0,1-7.448-1.911c-2.358-1.2-4.714-2.511-6.992-3.779L26.31,4.729c-3.914-2.163-7.487-4-11.6-4.6C9.359-.641,3.8,1.993.2,7L0,7.268,2.294,9.159l.542-.592c.2-.224.392-.427.577-.635,4.24-4.8,8.9-5.973,14.66-3.674a68.462,68.462,0,0,1,7.412,3.684l1.074.588c.689.375,1.374.76,2.057,1.143A44.955,44.955,0,0,0,38.069,14.044Z"
                              transform="translate(0 60.347) rotate(-30)"/>
                    </g>
                </g>
            </svg>
        );
    }
}

const styledSend = withStyles(SendStyles)(Send);

export default withTranslation()(styledSend);