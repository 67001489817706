import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const DownloadStyles = (theme: Theme) => createStyles({
    a: {
        fill: 'none !important',
        strokeWidth: 1.5,
        strokeLinecap: 'round',
        strokeMiterlimit: 10
    }
});

interface DownloadProps extends WithStyles<typeof DownloadStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface DownloadState {
    color: string,
    width: number,
    height: number,
}

class Download extends React.Component<DownloadProps, DownloadState> {
    constructor(props: DownloadProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 27,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.011 26.963">
                <g transform="translate(-1252.75 -756)">
                    <g transform="translate(1277.801 767.112) rotate(135)">
                        <path className={classes.a} stroke={this.state.color} d="M0,0H9.616V9.616" transform="translate(4.17 0)"/>
                        <line className={classes.a} stroke={this.state.color} x1="9.392" y2="9.199" transform="translate(0 4.394)"/>
                    </g>
                    <path className={classes.a} stroke={this.state.color} d="M11.552,0V12.153H-16.959V0" transform="translate(1270.459 770.061)"/>
                </g>
            </svg>
        );
    }
}

const styledDownload = withStyles(DownloadStyles)(Download);

export default withTranslation()(styledDownload);