import React, {ReactNode} from 'react';

interface LoaderProps {
    status: string,
    before: ReactNode,
    loading: ReactNode,
    success: ReactNode,
    error: ReactNode
}

interface LoaderState {

}

class Loader extends React.Component<LoaderProps, LoaderState> {
    constructor(props: LoaderProps) {
        super(props);
        this.state = {}
    }

    render() {

        let content = this.props.before;
        switch(this.props.status){
            case 'loading':
                content = this.props.loading;
                break;
            case 'success':
                content = this.props.success;
                break;
            case 'error':
                content = this.props.error;
                break;
            default:
                content = this.props.before;
        }

        return content;
    }
}

export default Loader;