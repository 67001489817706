import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {Norm2018} from "../../Norms";
import {SMextSystemData} from "../../FormData/SystemData";

const SignHintBoxStyles = (theme: Theme) => createStyles({
    root: {
        boxShadow: 'none'
    },
    background: {
        height: '100%',
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    }
});

interface SignHintBoxProps extends WithStyles<typeof SignHintBoxStyles>, WithTranslation {
    className?: string,
    norm: Norm2018,
}

interface SignHintBoxState {

}

class SignHintBox extends React.Component<SignHintBoxProps, SignHintBoxState> {

    render() {
        const classes = this.props.classes;

        return (
            <Paper square={true} className={classes.root + ' ' + this.props.className}>
                <CardContent className={classes.background}>
                    <Box>
                        <Typography variant="h3">
                            {this.props.t('results.sMext.hint.headline')}
                        </Typography>
                        <Typography>
                        {this.props.t('results.sMext.hint.bodytext')}
                        </Typography>
                        <TextField
                            variant="standard"
                            label={this.props.t('results.sMext.hint.maxPersons')}
                            disabled
                            value={this.props.norm.system instanceof SMextSystemData ? this.props.norm.calcMaxPersons() : ''}
                        />
                    </Box>
                </CardContent>
            </Paper>
        );
    }
}

const styledSignHintBox = withStyles(SignHintBoxStyles)(SignHintBox);

export default withTranslation()(styledSignHintBox);