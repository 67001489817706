import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CheckSVG from '../SVGs/Check';
import WarningSVG from '../SVGs/Warning';
import ToxicitySVG from '../SVGs/Toxicity';
import DownloadLink from "./DownloadLink";
import {Norm2018, Norm2022} from "../../Norms";
import {PacIfSystemData, SMextSystemData} from "../../FormData/SystemData";

const ResultBoxStyles = (theme: Theme) => createStyles({
    root: {
        boxShadow: 'none'
    },
    background: {
        ...theme.grid.oneColumn,
        ...{
            height: '100%',
            background: theme.palette.deadZone.main,
            color: theme.palette.deadZone.contrastText,
            textAlign: 'center'
        }
    },
    backgroundError: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    backgroundSuccess: {
        background: theme.palette.success.main,
        color: theme.palette.success.contrastText
    },
    backgroundToxic: {
        background: theme.palette.toxicZone.main,
        color: theme.palette.toxicZone.contrastText
    },
    content: {
        alignSelf: 'center'
    },
    table: {
        '& .MuiTableCell-root': {
            color: theme.palette.primary.contrastText,
            border: '1px solid white'
        },
        '& .MuiTableCell-head': {
            fontWeight: 600
        }
    },
    green: {
        background: theme.palette.success.main
    },
    red: {
        background: theme.palette.error.main
    },
    download: {
        position: 'relative',
        display: 'inline-block',
        padding: theme.spacing(2) + ' ' + theme.spacing(10),
        marginTop: theme.spacing(6),
        color: '#FFF',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover, &:active': {
            color: '#FFF'
        },
        '& svg': {
            position: 'absolute',
            left: 0,
            bottom: '50%',
            transform: 'translateY(50%)'
        }
    }
});

interface ResultBoxProps extends WithStyles<typeof ResultBoxStyles>, WithTranslation {
    className?: string,
    norm: Norm2018|Norm2022
}

interface ResultBoxState {

}

class ResultBox extends React.Component<ResultBoxProps, ResultBoxState> {

    render() {
        const classes = this.props.classes;

        let managementNecessary = this.props.norm.getData().necessaryMeasures.length > 0;
        if((this.props.norm.system instanceof PacIfSystemData || this.props.norm.system instanceof PacIfSystemData)) {
            managementNecessary = this.props.norm.getData().necessaryMeasures.length > 1;
        }

        let backgroundClass = classes.background;
        let icon = <WarningSVG/>;
        let resultCase = JSON.stringify(managementNecessary);

        if (this.props.norm.isToxicityHandlingNecessary()) {
            backgroundClass += ' ' + classes.backgroundToxic;
            icon = <ToxicitySVG/>;
            resultCase = 'toxic';
        }

        if (!this.props.norm.isInstallationPossible()) {
            resultCase = 'impossible';
        }

        if (managementNecessary || !this.props.norm.isInstallationPossible()) {
            backgroundClass += ' ' + classes.backgroundError;
        } else {
            backgroundClass += ' ' + classes.backgroundSuccess;
            icon = <CheckSVG/>;
        }

        let sMext = this.props.norm.system instanceof SMextSystemData ? '.sMext' : '';

        return (
            <Paper square={true} className={classes.root + ' ' + this.props.className}>
                <CardContent className={backgroundClass}>
                    <Box className={classes.content}>
                        {icon}
                        <Box>
                            <Typography variant="h4">
                                {this.props.t('results' + sMext + '.necessary.' + resultCase + '.headline')}
                            </Typography>
                            <Typography>
                                {this.props.t('results' + sMext + '.necessary.' + resultCase + '.bodytext')}
                            </Typography>
                            {resultCase === 'impossible' &&
                            <DownloadLink system={this.props.norm.system} className={classes.download}/>
                            }
                        </Box>
                    </Box>
                </CardContent>
            </Paper>
        );
    }
}

const styledResultBox = withStyles(ResultBoxStyles)(ResultBox);

export default withTranslation()(styledResultBox);