import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';
import {TextValidator} from 'react-material-ui-form-validator';

import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Check from '@mui/icons-material/Check';
import Replay from '@mui/icons-material/Replay';

import SectionCard from '../../Partials/SectionCard';
import Loader from '../../Partials/Loader';

import SendSVG from '../../SVGs/Send';
import CheckSVG from '../../SVGs/Check';
import {Norm2018, Norm2022} from "../../../Norms";
import MetaData from "../../../FormData/MetaData";

const ProjectStyles = (theme: Theme) => createStyles({
    root: {},
    cardContent: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: theme.spacing(6)
    },
    rightSide: {
        position: 'relative',
        paddingBottom: theme.spacing(12)
    },
    button: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    loaderWrap: {
        margin: '0 auto',
        width: '100%',
        maxWidth: 300,
        height: 300,
        '& svg': {
            width: 266,
            maxWidth: '100%',
            height: 266
        }
    },
    checkbox: {
        border: '1px solid ' + theme.palette.secondary.contrastText,
        width: 30,
        height: 30
    },
    checkedCheckbox: {
        border: '1px solid ' + theme.palette.secondary.contrastText,
        background: theme.palette.secondary.contrastText,
        width: 30,
        height: 30
    },
    progress: {
        '& .MuiCircularProgress-colorPrimary': {
            color: theme.palette.success.main
        }
    },
    '@media screen and (min-width: 1400px)': {
        cardContent: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: theme.spacing(20)
        }
    }
});

interface ProjectProps extends WithStyles<typeof ProjectStyles>, WithTranslation {
    norm: Norm2018 | Norm2022,
    updateNorm: any,
    error: boolean | null,
    loading: boolean,
    resetApp: any
}

interface ProjectState {

}

class Project extends React.Component<ProjectProps, ProjectState> {
    handleValueChange = (key: keyof MetaData, event: any) => {
        this.props.updateNorm({
            [key]: event.currentTarget.value
        });
    };

    handleCheckedChange = (key: keyof MetaData, event: any) => {
        this.props.updateNorm({
            [key]: event.currentTarget.checked
        });
    };

    render() {
        const classes = this.props.classes;

        let status = 'before';
        if (this.props.loading && this.props.error === null) {
            status = 'loading';
        }
        if (!this.props.loading && this.props.error !== null && !this.props.error) {
            status = 'success';
        } else if (!this.props.loading && this.props.error) {
            status = 'error'
        }

        return (
            <SectionCard contentClass={classes.cardContent}>
                <Typography component="div">
                    {/* @ts-ignore */}
                    <TextValidator
                        variant="standard"
                        label={this.props.t('project.name')}
                        name="name"
                        type="text"
                        onChange={this.handleValueChange.bind(this, "name")}
                        value={this.props.norm.meta.name}
                        validators={['required']}
                        errorMessages={[this.props.t('error.required')]}
                    />

                    {/* @ts-ignore */}
                    <TextValidator
                        variant="outlined"
                        label={this.props.t('project.description')}
                        name="description"
                        type="textarea"
                        multiline
                        rows={8}
                        onChange={this.handleValueChange.bind(this, "description")}
                        value={this.props.norm.meta.description}
                        validators={[]}
                        errorMessages={[]}
                    />

                    <FormControlLabel
                        label={this.props.t('project.email.send')}
                        control={<Checkbox icon={<div className={classes.checkbox}/>}
                                           checkedIcon={
                                               <div className={classes.checkedCheckbox}>
                                                   <Check color="secondary"/>
                                               </div>}
                                           color="default"/>}
                        labelPlacement="end"
                        checked={this.props.norm.meta.send}
                        onChange={this.handleCheckedChange.bind(this, "send")}
                    />

                    {/* @ts-ignore */}
                    <TextValidator
                        variant="standard"
                        label={this.props.t('project.email.address')}
                        name="email"
                        type="text"
                        disabled={!this.props.norm.meta.send}
                        onChange={this.handleValueChange.bind(this, "email")}
                        value={this.props.norm.meta.email}
                        validators={this.props.norm.meta.email ? ['required', 'isEmail'] : ['isEmail']}
                        errorMessages={this.props.norm.meta.email ? [this.props.t('error.required'), this.props.t('error.email')] : [this.props.t('error.email')]}
                    />
                </Typography>
                <Box
                    className={classes.rightSide}
                    component="div">
                    <Box className={classes.loaderWrap}>
                        <Loader status={status}
                                before={<SendSVG color="#464646"/>}
                                loading={<Box className={classes.progress}>
                                    <CircularProgress size="266px"/>
                                </Box>}
                                success={<CheckSVG color="#b7bc23"/>}
                                error={<Replay color="primary"/>}/>
                    </Box>
                    <Typography style={{padding: '30px 0'}}>
                        {(status !== 'success' && status !== 'error') && this.props.t('project.outro.initial')}
                        {(status === 'success' || status === 'error') && this.props.t('project.outro.' + status)}
                    </Typography>
                    {(status !== 'success') &&
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            type="submit"
                            disabled={this.props.loading}>
                            {(status === 'error') && this.props.t('project.button.error')}
                            {(status !== 'error') && this.props.t('project.button.initial')}
                        </Button>}
                    {(status === 'success') &&
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.props.resetApp.bind(this)}
                            disabled={this.props.loading}>
                            {this.props.t('project.button.success')}
                        </Button>}
                </Box>
            </SectionCard>
        );
    }
}

const styledProject = withStyles(ProjectStyles)(Project);

export default withTranslation()(styledProject);