import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import CircularProgress from '@mui/material/CircularProgress';


const LoadingStyles = (theme: Theme) => createStyles({
    root: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    progress: {
        position: 'absolute',
        bottom: '50%',
        right: '50%',
        transform: 'translate(50%, 50%)'
    },
});

interface LoadingProps extends WithStyles<typeof LoadingStyles>, WithTranslation {
    className?: string,
    progressClassName?: string
}

interface LoadingState {

}

class Loading extends React.Component<LoadingProps, LoadingState> {
    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root + (this.props.className ? ' ' + this.props.className : '')}>
                <div className={classes.progress + (this.props.progressClassName ? ' ' + this.props.progressClassName : '')}>
                    <CircularProgress/>
                </div>
            </div>
        );
    }
}

const styledLoading = withStyles(LoadingStyles)(Loading);

export default withTranslation()(styledLoading);