import { createTheme, Theme, adaptV4Theme } from '@mui/material/styles';
import {ColorPartial, PaletteColor, SimplePaletteColorOptions} from '@mui/material/styles/createPalette';

const spacing = 5;
const gridGap = 6 * spacing;

const contentContainer = {
    width: 'calc(100% - ' + 6 * spacing + 'px)',
    maxWidth: 1560,
    margin: '0 auto',
    '@media screen and (min-width: 800px)': {
        width: 'calc(100% - ' + 12 * spacing + 'px)',
    }
};

const grid = {
    oneColumn: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: gridGap + 'px 0'
    },
    twoColumns: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '0 ' + gridGap + 'px'
    },
    threeColumns: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: gridGap
    }
};

declare module '@mui/material/styles/createTheme' {
    interface Theme {
        contentContainer: typeof contentContainer,
        grid: typeof grid
    }

    interface DeprecatedThemeOptions {
        contentContainer?: typeof contentContainer,
        grid?: typeof grid
    }
}

declare module '@mui/material/styles/adaptV4Theme' {
    interface Theme {
        contentContainer: typeof contentContainer,
        grid: typeof grid
    }

    interface DeprecatedThemeOptions {
        contentContainer?: typeof contentContainer,
        grid?: typeof grid
    }
}

declare module '@mui/material/styles/createPalette' {

    interface Palette {
        success: PaletteColor
        z0: SimplePaletteColorOptions
        z1: SimplePaletteColorOptions
        z2: SimplePaletteColorOptions
        z3: SimplePaletteColorOptions
        deadZone: SimplePaletteColorOptions
        toxicZone: SimplePaletteColorOptions
    }

    interface PaletteOptions {
        success?: SimplePaletteColorOptions | ColorPartial
        z0?: SimplePaletteColorOptions
        z1?: SimplePaletteColorOptions
        z2?: SimplePaletteColorOptions
        z3?: SimplePaletteColorOptions
        deadZone?: SimplePaletteColorOptions
        toxicZone?: SimplePaletteColorOptions
    }
}

const palette = {
    primary: {
        main: '#ee1b24',
        contrastText: '#FFF'
    },
    secondary: {
        main: '#E8E5DE',
        contrastText: '#464646'
    },
    text: {
        primary: '#464646'
    },
    success: {
        main: '#B7BC23',
        contrastText: '#FFF'
    },
    error: {
        main: '#ee1b24',
    },
    z0: {
        main: '#E8E5DE',
        contrastText: '#464646'
    },
    z1: {
        main: '#B7BC23',
        contrastText: '#FFF'
    },
    z2: {
        main: '#FFDA5F',
        contrastText: '#464646'
    },
    z3: {
        main: '#ee1b24',
        contrastText: '#FFF'
    },
    deadZone: {
        main: '#777',
        contrastText: '#FFF'
    },
    toxicZone: {
        main: '#00098b',
        contrastText: '#FFF'
    }
};

const typography = {
    fontFamily: 'Roboto, Helvetica, sans-serif',
    htmlFontSize: 16,
    h1: {
        fontSize: '2.5rem',
        fontWeight: 700,
        'text-transform': 'uppercase',
        '@media screen and (min-width: 800px)': {
            fontSize: '3rem',
        }
    },
    h2: {
        fontSize: '1.25rem',
        fontWeight: 500,
        '@media screen and (min-width: 800px)': {
            fontSize: '1.5rem',
        }
    },
    h3: {
        fontSize: '1.1rem',
        fontWeight: 500,
        marginBottom: 2*spacing,
        'text-transform': 'uppercase',
        '@media screen and (min-width: 800px)': {
            fontSize: '1.25rem',
        }
    },
    h4: {
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: 2*spacing
    },
    body1: {
        fontSize: '1rem',
        fontWeight: 400
    },
    body2: {
        fontSize: '1rem',
        fontWeight: 500
    },
    button: {
        fontSize: '1.25rem',
        fontWeight: 700,
        letterSpacing: 1.5,
        '@media screen and (min-width: 800px)': {
            fontSize: '1.5rem'
        }
    }
};

const theme: Theme = createTheme(adaptV4Theme({
    spacing: spacing,
    contentContainer: contentContainer,
    grid: grid,
    typography: typography,
    palette: palette,
    overrides: {
        MuiFilledInput: {
            root: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
            }
        },
        MuiCardHeader: {
            root: {
                background: palette.primary.main,
                color: 'white',
                padding: 6*spacing,
                height: 60,
                '& img': {
                    width: 30,
                    height: 30
                }
            },
            content: {
                'text-transform': 'uppercase',
            }
        },
        MuiCardContent: {
            root: {
                padding: 6*spacing
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
                margin: 3*spacing + 'px 0'
            }
        },
        MuiButton: {
            contained: {
                padding: 3*spacing,
                borderRadius: 0,
                boxShadow: 'none',
                lineHeight: 1,
                '&:hover': {
                    boxShadow: 'none'
                }
            }
        },
        MuiCheckbox: {
            root: {
                margin: 3*spacing + 'px 0 0'
            }
        },
        MuiFormControlLabel: {
            label: {
                marginTop: 3*spacing
            }
        },
        MuiFormLabel: {
            filled: {
                color: palette.text.primary
            }
        },
        MuiInput: {
            root: {
                '&:before': {
                    borderColor: palette.text.primary
                }
            }
        },
    }
}));

export default theme;