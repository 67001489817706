import AbstractSystemData from "./AbstractSystemData";
import {FieldDefinition} from "../../DataObjects";

export default class HvrfSystemData extends AbstractSystemData {
    constructor(
        numberOfUnits: number = 2
    ) {
        super(numberOfUnits);
    }

    get id(): string {
        return 'hvrf';
    }

    get formDefinition(): FieldDefinition[] {
        // @ts-ignore
        let formDefinition = super.formDefinition;

        for(let i=0; i < formDefinition.length; i++) {
            if (formDefinition[i].id === 'numberOfUnits') {
                formDefinition[i].options = {
                    type: 'counter',
                    start: 2,
                    max: 4,
                    orMore: true
                };
            }
        }

        return formDefinition;
    }
}