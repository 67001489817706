import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

const AlertStyles = (theme: Theme) => createStyles({
    a: {
        fill: "none !important",
        strokeWidth: 1.7575,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
    },
    b: {
        fill: "none !important",
        strokeWidth: 1.7575,
        strokeLinecap: "square",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
    },
    c: {
        enableBackground: "new 0 0 32.6 30.6",
    }
});

interface AlertProps extends WithStyles<typeof AlertStyles>, WithTranslation {
    color?: string,
    width?: number,
    height?: number,
}

interface AlertState {
    color: string,
    width: number,
    height: number,
}

class Alert extends React.Component<AlertProps, AlertState> {
    constructor(props: AlertProps) {
        super(props);
        this.state = {
            color: this.props.color ? this.props.color : '#fff',
            width: this.props.width ? this.props.width : 30,
            height: this.props.height ? this.props.height : 30,
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <svg width={this.state.width} height={this.state.height} xmlns="http://www.w3.org/2000/svg" viewBox="3 0 30.6 30.6">
                <path className={classes.a} stroke={this.state.color} d="M26.3,5.5c-4.7,0-5.3-0.8-9.3-4.6h-0.1c-3.9,3.7-4.6,4.6-9.3,4.6c-0.6,0-1.2-0.1-1.8-0.2v13.4 c0,5.2,5.6,10.9,11.1,10.9l0,0C22.4,29.7,28,24,28,18.8V5.4C27.5,5.5,26.9,5.5,26.3,5.5z"/>
                <circle className={classes.a} stroke={this.state.color} cx="16.9" cy="16.3" r="6.7"/>
                <polyline className={classes.b} stroke={this.state.color} points="14.1,15.9 16.7,18.5 23.6,9.6 "/>
    </svg>

        );
    }
}

const styledAlert = withStyles(AlertStyles)(Alert);

export default withTranslation()(styledAlert);